import { blue, neutral, purple } from "../../../constants/colorsPallete";
import {
  type CropProductionAverage,
  type CropProductionData,
  type CropProductionYieldDataType,
  type DataSet,
} from "./types";

// export const calculateCountInRange = (
//   ranges: string[],
//   details: CropProductionYieldDataType[],
//   calculateLevel: (detail: CropProductionYieldDataType) => number
// ): number[] => {
//   return ranges.map((range) => {
//     const [start, end] = range.split(" - ").map(Number);
//     return details.reduce((count, detail) => {
//       const level = calculateLevel(detail);
//       return level >= start && level <= end ? count + 1 : count;
//     }, 0);
//   });
// };

export const calculateYieldIrrigatedFrequency = (
  ranges: string[],
  cropDetails: CropProductionYieldDataType[]
): number[] => {
  const yieldLevels = cropDetails.map((farmer) => parseInt(farmer.yield));
  return ranges.map((range) => {
    const [start, end] = range.split(" - ").map(Number);
    return yieldLevels.reduce((count, level) => {
      return level >= start && level <= end ? count + 1 : count;
    }, 0);
  });
};

export const calculateNitrogenEfficiencyCount = (
  ranges: string[],
  growersDetails: CropProductionYieldDataType[]
): number[] => {
  return ranges.map((range) => {
    const [start, end] = range.split(" - ");
    const count = growersDetails.reduce(
      (count: number, crop: CropProductionYieldDataType) => {
        const { nitrogen, yield: growerYield } = crop;
        const level = !(+nitrogen && +growerYield)
          ? 0
          : +nitrogen / +growerYield;
        if (level >= +start && level <= +end) {
          return count + 1;
        }
        return count;
      },
      0
    );

    return count;
  });
};

export const calculateNitrogenAmountCount = (
  ranges: string[],
  growersDetails: CropProductionYieldDataType[]
): number[] => {
  return ranges.map((range) => {
    const [start, end] = range.split(" - ").map(Number);

    const count = growersDetails.reduce(
      (count: number, farmer: CropProductionYieldDataType) => {
        const level = parseFloat(farmer?.nitrogen);
        if (level >= start && level <= end) {
          return count + 1;
        }
        return count;
      },
      0
    );

    return count;
  });
};

export const checkWholeNumberOrDecimal = (elements: number[]): boolean => {
  const isNumber = elements.every((element) => Number.isInteger(element));
  return isNumber;
};

export const generateNumOfIntervals = (roundedValue: number): number => {
  const numbdivideBy10 = roundedValue / 10;
  const numbdivideBy100 = roundedValue / 100;
  const numbdivideBy200 = roundedValue / 200;
  const numbdivideBy500 = roundedValue / 500;
  const numbdivideBy1000 = roundedValue / 1000;
  const numOfIntervals =
    roundedValue === 10
      ? 2
      : numbdivideBy10 <= 10
      ? numbdivideBy10
      : numbdivideBy100 <= 10
      ? numbdivideBy100
      : numbdivideBy200 <= 10
      ? numbdivideBy200
      : numbdivideBy500 <= 10
      ? numbdivideBy500
      : numbdivideBy1000;
  return numOfIntervals;
};

export const customRound = (
  value: number,
  roundType: "ceil" | "floor"
): number => {
  if (value < 10 && roundType === "floor") {
    return 0;
  } else if (value < 100 && roundType === "ceil") {
    return Math.ceil(value / 10) * 10;
  } else {
    const length = Math.floor(Math.log10(value)) + 1;
    let factor;

    if (length === 2) {
      factor = 10;
    } else if (length === 3) {
      factor = 100;
    } else {
      const remainder = value % 1000;
      factor = remainder < 500 ? 500 : 1000;
    }

    return roundType === "ceil"
      ? Math.ceil(value / factor) * factor
      : Math.floor(value / factor) * factor;
  }
};

export const calculateRanges = (items: number[]): string[] => {
  const isNumber = checkWholeNumberOrDecimal(items);
  const minValue1 = Math.min(...items);
  const minValue = customRound(minValue1, "floor");
  const maxValue1 = Math.max(...items);
  const maxValue = customRound(maxValue1, "ceil");

  const dataRange = maxValue - minValue;
  const numIntervals = generateNumOfIntervals(dataRange);
  const intervalSize = (maxValue - minValue) / numIntervals;
  const roundOffIntervalSize =
    intervalSize < 1
      ? Math.round(intervalSize * 2) / 2
      : Math.ceil(intervalSize);
  const ranges: string[] = [];
  for (let i = 0; i < numIntervals; i++) {
    const startRange = minValue + i * roundOffIntervalSize;
    const endRange = startRange + roundOffIntervalSize;
    ranges.push(
      `${isNumber ? startRange : startRange.toFixed(2)} - ${
        isNumber ? endRange : endRange.toFixed(2)
      }`
    );
  }
  for (let i = 1; i < ranges.length; i++) {
    const [start, end] = ranges[i].split("-");
    const startByNextInt = isNumber
      ? parseFloat(start) + 1
      : (parseFloat(start) + 0.01).toFixed(2);
    ranges[i] = `${startByNextInt} - ${+end}`;
  }
  return ranges;
};

export const lablesSet = (labels: string[]): Array<[string, string]> => {
  return labels?.map((label: string) => {
    const words = label.split(" ");
    return [words.slice(0, 2).join(" "), words.slice(2, 4).join(" ")];
  });
};

export const livingRootLablesSet = (
  labels: string[]
): Array<[string, string]> => {
  return labels?.map((label: string) => {
    const words = label.split(" ");
    return [words.slice(0, -2).join(" "), words.slice(-2).join(" ")];
  });
};

export const soilHealthData = (
  data: number[],
  labels: string[]
): Array<{ label: string; count: number; backgroundColor: string }> => {
  const labelColors: Record<string, string> = {};
  labels.forEach((label: string, index: number) => {
    const color =
      index < 9
        ? (purple as Record<number, string>)[100 - index * 10]
        : (blue as Record<number, string>)[20 + (index - 9) * 10];

    labelColors[label] = color;
  });

  const result: Array<{
    label: string;
    count: number;
    backgroundColor: string;
  }> = labels.map((label: string, index: number) => {
    return {
      label,
      count: data[index],
      backgroundColor: labelColors[label],
    };
  });
  return result;
};

export const calculateCropProductionAverages = (
  cropDetails: CropProductionData
): CropProductionAverage[] => {
  const { cropperformance, totalFarmers } = cropDetails;
  const totalYield = cropperformance.reduce(
    (acc: number, crop: CropProductionYieldDataType) =>
      acc + parseFloat(crop.yield),
    0
  );
  const totalNitrogen = cropperformance.reduce(
    (acc: number, crop: CropProductionYieldDataType) =>
      acc + parseFloat(crop.nitrogen),
    0
  );
  const averageLevel = totalYield / totalFarmers;
  const averageNitrogen = totalNitrogen / totalFarmers;

  return [
    {
      average_of_yield: `${averageLevel.toFixed(2)}`,
      nitrogen: `${averageNitrogen.toFixed(2)}`,
    },
  ];
};

export const formatLabel = (label: string): string => {
  const formattedLabel = label
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase();
  return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
};

export const getChartScales = (
  options: any,
  truncatedLabels: string[]
): any => {
  return {
    ...options.scales,
    y: {
      ...options.scales?.y,
      ticks: {
        callback: (value: string | number, index: number) =>
          truncatedLabels[index],
        color: neutral["50"],
      },
    },
  };
};
export const getTooltip = (options: any, labels: any): any => {
  return {
    ...options?.plugins,
    tooltip: {
      callbacks: {
        title: (context: any) => labels[context[0].dataIndex],
      },
    },
  };
};
export const getTruncatedLabels = (
  labels: any,
  labelLength: number = 27
): string[] => {
  return labels?.map((label: string) =>
    label.length > labelLength ? `${label.substring(0, labelLength)}...` : label
  );
};
export const getChartData = (
  labels: any,
  data: any,
  color: any,
  graphLabel?: string
): DataSet => {
  return {
    labels: getTruncatedLabels(labels),
    datasets: [
      {
        label: graphLabel ?? "",
        data,
        backgroundColor: color,
        hoverBackgroundColor: color,
      },
    ],
  };
};

export function calculateGraphDistribution(value: number): number[] {
  const maxColumnsPerGraph = 14;
  const minColumnsPerGraph = 8;
  const maxTotalColumns = 100;
  const maxGraphs = Math.ceil(value / maxColumnsPerGraph);
  const columnDistributions: number[] = [];
  let remainingValue = value;
  for (let i = 0; i < maxGraphs; i++) {
    const maxColumns = Math.min(maxColumnsPerGraph, remainingValue);
    const minColumns = i === 0 ? minColumnsPerGraph : 1;
    const columnsForGraph = Math.max(
      minColumns,
      Math.min(maxColumns, Math.floor(remainingValue / (maxGraphs - i)))
    );
    remainingValue -= columnsForGraph;
    columnDistributions.push(columnsForGraph);
  }
  // Adjust last graph's columns if total exceeds maxTotalColumns
  const totalColumns = columnDistributions.reduce((acc, curr) => acc + curr, 0);
  if (totalColumns > maxTotalColumns) {
    const lastGraphIndex = columnDistributions.length - 1;
    columnDistributions[lastGraphIndex] -= totalColumns - maxTotalColumns;
  }
  return columnDistributions;
}
export const divideArrayByGraphColumnRange = (
  labels: string[] | number[],
  divisions: number[]
): any[][] => {
  const arrayOfArrays: any = [];
  let currentIndex = 0;
  for (let i = 0; i < divisions.length; i++) {
    const endIndex = currentIndex + divisions[i];
    const subarray = labels.slice(currentIndex, endIndex);
    arrayOfArrays.push(subarray);
    currentIndex = endIndex;
  }
  return arrayOfArrays;
};

export const calculateAndGetChartData = (
  label: string,
  color: any,
  farmPropertyName: string,
  growersDetails: Array<Record<string, any>>,
  getNumberOfElementOccurences: (ranges: any, growerDetails: any) => number[]
): DataSet => {
  const requiredData: number[] = getRequiredGrowerDetails(
    growersDetails,
    farmPropertyName
  );
  const ranges = calculateRanges(requiredData);
  const numberOfElements: number[] = getNumberOfElementOccurences(
    ranges,
    growersDetails
  );
  return getChartData(ranges, numberOfElements, color, label);
};

export const getRequiredGrowerDetails = (
  growersDetails: any,
  farmPropertyName: string
): number[] => {
  return growersDetails.map((farmer: any) =>
    parseInt(farmer?.[farmPropertyName])
  );
};
