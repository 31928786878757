import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { purple } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { type GenericDataType } from "../types";
import { getChartData, getChartScales, getTooltip } from "../utils";

const HumanAndAnimalHealth = ({
  growersDetails,
  options,
}: {
  growersDetails: GenericDataType;
  options: ChartOptions<"bar">;
}): ReactElement => {
  const { labels, data } = growersDetails;
  options.plugins = getTooltip(options, labels);
  const chartData = getChartData(labels, data, purple["40"]);
  options.scales = getChartScales(options, chartData.labels);

  return <HorizontalBarChart options={options} data={chartData} height={290} />;
};

export default HumanAndAnimalHealth;
