import { Col, Row } from "antd";
import { useContext, type ReactElement } from "react";
import { ManagementPracticeDataSetContext } from "..";
import { InlineLoader } from "../../../../shared/components/Loader";
import { GetGraphComponent } from "../getGraphComponent";
import SoilHealth from "../soil-health/SoilHealth";
import { type GraphDetailsType } from "../types";
import CoverCropGrown from "./CoverCropsGrown";
import LivingRoot from "./LivingRoot";
import TillagePercentage from "./TillagePercentage";

const WaterQuality = (): ReactElement => {
  const { answerApiLoading, answerApiTransformedData } = useContext(
    ManagementPracticeDataSetContext
  );

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Tillage percentage",
      graph: (
        <TillagePercentage growersDetails={answerApiTransformedData.tillage} />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Days with a living root in the ground",
      graph: (
        <LivingRoot
          growersDetails={answerApiTransformedData["days-with-living-root"]}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Cover crops grown",
      graph: (
        <CoverCropGrown
          growersDetails={answerApiTransformedData["cover-crops-grown"]}
        />
      ),
      loading: answerApiLoading,
    },
  ];

  return (
    <>
      <Row gutter={16} className="overview-context water-quality">
        {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
          return GetGraphComponent(
            { ...graphDetails, waterQuality: true },
            index
          );
        })}
      </Row>
      <Row className="overview-context water-quality">
        <Col span={24}>
          {answerApiLoading ? (
            <InlineLoader
              top="55%"
              position="relative"
              text="Loading graph..."
            />
          ) : (
            <SoilHealth
              soilDetails={answerApiTransformedData?.["soil-health-1"]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
export default WaterQuality;
