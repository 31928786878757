import { Card, Col } from "antd";
import React, { type ReactElement } from "react";
import { InlineLoader } from "../../../shared/components/Loader";
import { type GraphDetailsType } from "./types";
import "../../style.less";

// TODO: remove the hardcoded size value below
export const GetGraphComponent = (
  {
    title,
    graph,
    loading,
    crop = false,
    waterQuality = false,
  }: GraphDetailsType,
  index: number
): ReactElement => {
  const size = crop
    ? index < 2
      ? 12
      : 24
    : waterQuality
    ? index <= 2
      ? 8
      : 24
    : index <= 3
    ? 12
    : 24;
  return (
    <Col xs={24} xl={size}>
      <Card className="overview-context-farms">
        <h3>{title}</h3>
        {loading ? (
          <InlineLoader top="55%" position="relative" text="Loading graph..." />
        ) : (
          graph
        )}
      </Card>
    </Col>
  );
};
