import { COUNTRY_ABBREVIATION } from "./constant";
import { type FarmsDetail, type CountriesData } from "./types";

export const formatNumber = (
  num: number,
  isLastRange: boolean = false
): string => {
  if (!isLastRange && num >= 1000) {
    if (num % 1000 === 0) {
      return (num / 1000).toString() + "k";
    } else {
      return (num / 1000).toFixed(1) + "k";
    }
  } else if (isLastRange && num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  }
  return num.toFixed(2).toString();
};

const countByProperty = (
  farmsDetails: FarmsDetail[],
  ranges: string[],
  property: string
): number[] => {
  const levelCounts = new Array(ranges.length).fill(0);

  farmsDetails.forEach((framer) => {
    const area = parseInt(framer[property]);

    const convertToNumericValue = (range: string): number | null => {
      const match = range.match(/(\d+(\.\d+)?)(k)?/);

      if (match) {
        const value = parseFloat(match[1]);
        return match[3] === "k" ? value * 1000 : value;
      }
      return null;
    };

    for (let i = 0; i < ranges.length; i++) {
      const [startRange, endRange] = ranges[i]
        .split("-")
        .map((str) => str.trim());

      const start = convertToNumericValue(startRange);
      const end = convertToNumericValue(endRange);

      if (start !== null && end !== null && area >= start && area <= end) {
        levelCounts[i]++;
        break;
      }
    }
  });

  return levelCounts;
};

export const farmsDistributionLevel = (
  farmsDetails: FarmsDetail[],
  ranges: string[]
): number[] => {
  return countByProperty(farmsDetails, ranges, "totalArea");
};

export const focusIrrigatedLevel = (
  farmsDetails: FarmsDetail[],
  ranges: string[]
): number[] => {
  return countByProperty(farmsDetails, ranges, "irrigatedArea");
};

export const focusRainfedLevel = (
  farmsDetails: FarmsDetail[],
  ranges: string[]
): number[] => {
  return countByProperty(farmsDetails, ranges, "rainfedArea");
};

export const calculateAverage = (acres: number, totalArea: number): number => {
  const totalAcresAverage = acres / totalArea;
  return parseFloat(totalAcresAverage.toFixed(2)) * 100;
};

export const getTotalAcersByCountry = (
  overviewData: Record<string, any>,
  countries: CountriesData[]
): Record<string, number> => {
  const country: Record<string, number> = {};
  overviewData?.plans?.forEach((d: any) => {
    let countryName = COUNTRY_ABBREVIATION[d?.countryCode];
    if (d.countryCode !== "US") {
      // as the country map has the Full name of US, taking name from contants
      const country = countries?.find(
        (data: CountriesData) => data.code === d.countryCode
      );
      countryName = country?.name ?? "";
    }

    if (!country[countryName]) {
      country[countryName] = 0;
    }
    d?.planCropMap?.forEach((c: any) => {
      country[countryName] += parseInt(c.acres);
    });
  });
  return country;
};
