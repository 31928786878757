import { useContext, useEffect } from "react";
import { PartnerDetailsContext } from "../../pages";
import { useAmplitudeLogging } from "./useAmplitudeTracking";

export function useLogAmplitudeEvents(
  eventName: string,
  trackEvents: any,
  properties?: Record<string, unknown> | undefined
): void {
  useEffect(() => {
    const startTime: number = new Date().getTime();
    return () => {
      const duration = getDuration(startTime);
      trackEvents({ event: eventName, duration, ...properties });
    };
  }, []);
}

const getDuration = (startTime: number): string => {
  const endTimeStamp: number = new Date().getTime();
  const timeStampDifference = endTimeStamp - startTime;
  const seconds = timeStampDifference / 1000;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let duration: string = "";
  if (hours) {
    duration += `${hours} hr `;
  }
  if (minutes) {
    duration += `${minutes} min `;
  }
  duration += `${seconds} sec`;
  return duration;
};

export const useCallAmplitudeEvent = (eventName: string): void => {
  const { partner, report } = useContext(PartnerDetailsContext);
  const trackEvents = useAmplitudeLogging(eventName);
  useLogAmplitudeEvents(eventName, trackEvents, {
    "Organization name": partner,
    "Report name": report,
  });
};
