import type { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState, type ReactElement } from "react";
import Table from "../../../../shared/components/Table";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import {
  getDriversRankedByScore,
  getOutcomeScore,
} from "../../../../transformer/summary/driver-ranked-by-score";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { OutcomeDataSetContext } from "../../OutcomeDetails";
import OutcomeStatuses from "../OutcomeStatuses";
import { type TableColumnDataType } from "../types";

const DriverRankedByScore = (): ReactElement => {
  const [outComeStatus, setOutComeStatus] = useState<
    Array<{ outcomeName: string; status: string }>
  >([]);
  const [tableData, setTableData] = useState<TableColumnDataType[]>([]);

  const columns: ColumnsType<TableColumnDataType> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Driver</div>,
      dataIndex: "driver",
      key: "driver",
      sorter: (a, b) => a.driver.localeCompare(b.driver),
      sortDirections: ["descend", "ascend"],
      width: "60%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Score</div>,
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Level</div>,
      dataIndex: "level",
      key: "level",
      sorter: (a, b) => a.level.localeCompare(b.level),
      sortDirections: ["descend", "ascend"],
    },
  ];
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);
  const eventName = `${APP_MODULES.SUMMARY}_${APP_MODULES.DRIVERS_RANKED_BY_SCORE}`;
  useCallAmplitudeEvent(eventName);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const tableData = await getDriversRankedByScore(outcomeOverviewContext);
      const outcomeScore = await getOutcomeScore(outcomeOverviewContext);
      setOutComeStatus(outcomeScore);
      setTableData(tableData);
    }
    void fetchData();
  }, []);

  return (
    <>
      <OutcomeStatuses outcomeStatusData={outComeStatus} />
      <div className="driver-table-main">
        <p className="title">Drivers ranked by score</p>
        <Table columns={columns} dataSource={tableData} />
      </div>
    </>
  );
};
export default DriverRankedByScore;
