import { render, screen } from "@testing-library/react";
import { type ReactElement, type MouseEvent } from "react";

export const getElementTargetID = (event: MouseEvent<HTMLElement>): string => {
  const elementTarget = event.currentTarget as HTMLElement;
  return elementTarget?.id;
};

export const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1] || " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

export const getByTestId = (elementName: string): HTMLElement => {
  return screen.getByTestId(elementName);
};

export const getChartElement = (
  chartReactElement: ReactElement,
  testId: string
): HTMLElement => {
  render(chartReactElement);
  return getByTestId(testId);
};
