import { type ReactElement } from "react";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import CommonOutcomesModule from "../CommonOutcomesModule";

const WaterImpactIrrigated = (): ReactElement => {
  const eventName = `${APP_MODULES.OUTCOMES}_${APP_MODULES.WATER_IMPACT_IRRIGATED}`;
  useCallAmplitudeEvent(eventName);

  return (
    <CommonOutcomesModule
      outcomeKey="6.0.0 - irrigated"
      outcomeName="Water impact levels"
    />
  );
};

export default WaterImpactIrrigated;
