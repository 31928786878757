export const APP_MODULES: Readonly<Record<string, string>> = {
  OVERVIEW: "Overview",
  CONTEXT: "context",
  ACRES_BY_COUNTRY: "Acres by country",

  SUMMARY: "Summary",
  OUTCOME_OVERVIEW: "Outcome overview",
  DRIVERS_RANKED_BY_SCORE: "Drivers ranked by score",
  OPPORTUNITIES_TO_IMPROVE_PERFORMANCE: "Opportunities to improve performance",
  OVERALL_LEADERSHIP_LEVELS: "Overall leadership levels",
  PRACTICE_IMPROVEMENT_OPPORTUNITIES: "Practice improvement opportunities",

  SAI_INDICATOR: "Sai Indicator",
  SAI_PERFORMANCE: "SAI performance",
  AVERAGE_SCORES: "Average scores",
  FARMERS_BY_PERCENTAGE_OF_QUESTIONS: "Farmers by % of questions",

  OUTCOMES: "Outcomes",
  BIODIVERSITY_AND_HABITAT: "Biodiversity and habitat",
  COMMUNITY_LEADERSHIP: "Community leadership",
  HUMAN_AND_ANIMAL_HEALTH: "Human and animal health",
  OPTIMAL_PRODUCTION: "Optimal production",
  SOIL_HEALTH: "Soil health",
  WATER_IMPACT_IRRIGATED: "Water impact irrigated",
  WATER_IMPACT_RAINFED: "Water impact rainfed",

  MANAGEMENT_PRACTICE: "Management Practice",
  CROP_PRODUCTIONS: "Crop productions",
  NUTRIENT_MANAGEMENT: "Nutrient management",
  PEST_MANAGEMENT: "Pest management",
  SOIL_HEALTH_TWO: "Soil health - 2",
  WATER_QUALITY: "Water quality",
};
