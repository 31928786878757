import React, { type ReactElement } from "react";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import CommonOutcomesModule from "../CommonOutcomesModule";

const SoilHealth = (): ReactElement => {
  const eventName = `${APP_MODULES.OUTCOMES}_${APP_MODULES.SOIL_HEALTH}`;
  useCallAmplitudeEvent(eventName);

  return <CommonOutcomesModule outcomeKey="5.0.0" outcomeName="Soil Health" />;
};

export default SoilHealth;
