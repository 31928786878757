import { Card, Col, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { useParams } from "react-router-dom";
import { OverviewDataSetContext } from "..";
import { InlineLoader } from "../../../../shared/components/Loader";
import Table from "../../../../shared/components/Table";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import AxiosInstance from "../../../../shared/utils/axios";
import { getFarmDetailsOverview } from "../../../../transformer/overview";
import { APP_MODULES } from "../../../amplitude-event-constants";
import "../../../style.less";
import { type GraphDetailsType } from "../../management-practices/types";
import "../../style.less";
import { type CropDetails, type FarmsDetail } from "../types";
import AverageCroppedAndNonCroppedAcres from "./AverageCroppedAndNonCroppedAcres";
import FarmsAcresDistribution from "./FarmsAcresDistribution";
import FocusCropAcresIrrigated from "./FocusCropAcresIrrigated";
import FocusCropAcresRainfed from "./FocusCropAcresRainfed";

const Context = (): ReactElement => {
  const { overviewData } = useContext(OverviewDataSetContext);
  const [cropsDetails, setCropsDetails] = useState<CropDetails>();
  const [farmsDetails, setFarmsDetails] = useState<FarmsDetail[]>([]);
  const { partnerId, cropYear } = useParams();

  const columns: ColumnsType<CropDetails> = [
    {
      title: <div>Crop</div>,
      dataIndex: "crop",
      key: "crop",
      align: "center",
    },
    {
      title: <div>Year</div>,
      dataIndex: "cropYear",
      key: "cropYear",
      align: "center",
    },
    {
      title: <div>Growers</div>,
      dataIndex: "growers",
      key: "growers",
      align: "center",
    },
    {
      title: <div>Total acres of crop</div>,
      dataIndex: "totalAcres",
      key: "totalAcres",
      align: "center",
    },
    {
      title: <div>Total acres of all crops</div>,
      dataIndex: "totalAcresOfAllCrops",
      key: "totalAcresOfAllCrops",
      align: "center",
    },
  ];

  useEffect(() => {
    if (overviewData?.plans) {
      setFarmsDetails(getFarmDetailsOverview(overviewData));
    }
  }, [overviewData]);

  const eventName = `${APP_MODULES.OVERVIEW}_${APP_MODULES.CONTEXT}`;
  useCallAmplitudeEvent(eventName);

  useEffect(() => {
    function fetchData(): void {
      AxiosInstance.get(
        `/report/overview/summary/${String(partnerId)}/${String(cropYear)}`
      )
        .then((cropDetails) => {
          setCropsDetails(cropDetails.data);
        })
        .catch((error) => {
          console.warn(error);
        });
    }
    fetchData();
  }, []);
  const { totalAcres, totalAcresOfAllCrops } = (cropsDetails ??
    {}) as CropDetails;
  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Farms acres distribution",
      graph: <FarmsAcresDistribution farmsDetails={farmsDetails} />,
    },
    {
      title: "Focus crop acres irrigated",
      graph: <FocusCropAcresIrrigated farmsDetails={farmsDetails} />,
    },
    {
      title: "Focus crops acres rainfed",
      graph: <FocusCropAcresRainfed farmsDetails={farmsDetails} />,
    },
    {
      title: "Primary crop area and other crops area",
      graph: (
        <AverageCroppedAndNonCroppedAcres
          totalAcres={totalAcres ?? 0}
          totalAcresOfAllCrops={(totalAcresOfAllCrops - totalAcres) ?? 0}
        />
      ),
    },
  ];
  return (
    <>
      <div className="outcome-title">
        Key findings, insights, and recommendations presented in this report.
      </div>
      <Table
        columns={columns}
        loading={cropsDetails === undefined}
        dataSource={
          cropsDetails
            ? [
                {
                  ...cropsDetails,
                  crop: Array.from(cropsDetails.crop).join(", "),
                },
              ]
            : []
        }
        outerBorderOnly={true}
      />
      <Row gutter={16} className="overview-context context">
        {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
          return getGraphComponent(graphDetails, index, farmsDetails);
        })}
      </Row>
    </>
  );
};
export default Context;

// TODO : make this component common with already made comp
export const getGraphComponent = (
  { title, graph }: GraphDetailsType,
  index: number,
  farmsDetails: FarmsDetail[]
): ReactElement => {
  const xlSize = index <= 3 ? 12 : 24;
  return (
    <Col xs={24} xl={xlSize}>
      <Card className="overview-context-farms">
        <h3>{title}</h3>
        {!farmsDetails?.length ? (
          <InlineLoader top="15%" position="relative" text="Loading graph..." />
        ) : (
          graph
        )}
      </Card>
    </Col>
  );
};
