import React, { type ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  type ChartData,
  type ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  ChartDataLabels
);
interface StackedBarChartProps {
  // labels: string[];
  data: ChartData<"bar">;
  options?: ChartOptions<"bar">;
  width?: number;
  height?: number;
  redraw?: boolean;
}
function StackedBarChart({
  data,
  options = {},
  width,
  height,
  ...props
}: StackedBarChartProps): ReactElement {
  options.responsive = options?.responsive ?? true;

  // Set Scale for Stacked BarChart
  options.scales = {
    ...options?.scales,
    x: {
      ...options?.scales?.x,
      stacked: true,
    },
    y: {
      ...options?.scales?.y,
      stacked: true,
    },
  };

  options.plugins = {
    ...options?.plugins,
    title: {
      align: "start", // all the charts display title on start
      ...options?.plugins?.title,
      display:
        options?.plugins?.title?.display ?? !!options?.plugins?.title?.text,
    },
    legend: {
      display: false, // default legend to false as most of the chart don't legends
      position: "top",
      ...options?.plugins?.legend,
    },
  };
  return (
    <Bar
      options={options}
      data={data}
      width={width}
      height={height}
      {...props}
    />
  );
}
export default StackedBarChart;
