import { createContext, type ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAxios from "../../../shared/custom-hooks/useAxios";
import { useCallAmplitudeEvent } from "../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../amplitude-event-constants";

export const OverviewDataSetContext = createContext<Record<string, any>>({});
export const Overview = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const { response } = useAxios(
    `/report/overview/${String(partnerId)}/${String(cropYear)}`
  );
  useCallAmplitudeEvent(APP_MODULES.OVERVIEW);

  return (
    <OverviewDataSetContext.Provider
      value={{
        overviewData: response,
      }}
    >
      <Outlet />
    </OverviewDataSetContext.Provider>
  );
};
