import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { type FC, type ReactElement } from "react";

interface loader {
  mask: boolean;
  message?: string;
}
interface inlineLoaderOrError {
  text?: string;
  [key: string]: any;
}
export const Loader: FC<loader> = ({ message, mask }): ReactElement => {
  return (
    <div>
      {mask && <div className="mask" />}
      <div className="loader-wrapper">
        <div className="loader-container">
          <div className="loader" />
          {Boolean(message) && (
            <div className="loader-text">{message ?? "Loading ..."}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const InlineErrorMessage: FC<inlineLoaderOrError> = ({
  text,
  ...props
}) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontStyle: "oblique",
        fontSize: "16px",
        color: "#acb4b7",
        fontFamily: "cursive",
        ...props,
      }}
    >
      <p>
        <InfoCircleOutlined style={{ fontSize: 34, color: "#acb4b7" }} />
      </p>
      {text ?? "Error occured while processing your request. Please retry."}
    </div>
  );
};

export const InlineLoader: FC<inlineLoaderOrError> = ({ text, ...props }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#a3dfa5" }} spin />
  );
  return (
    <div
      style={{
        textAlign: "center",
        fontStyle: "oblique",
        fontSize: "16px",
        color: "#747778",
        fontFamily: "cursive",
        padding: 10,
        ...props,
      }}
    >
      <p>
        <Spin indicator={antIcon} />
      </p>
      <p>{text ?? "Loading data..."}</p>
    </div>
  );
};
