import { Row } from "antd";
import React, { useContext, type ReactElement } from "react";
import { ManagementPracticeDataSetContext } from "..";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import "../../../style.less";
import { GetGraphComponent } from "../getGraphComponent";
import { type GraphDetailsType } from "../types";
import CropProductionAverages from "./CropProductionAverages";
import FertilizerAverage from "./FertilizerAverage";
import YieldAndFertilizer from "./YieldAndFertilizer";
import YieldIrrigated from "./YieldIrrigated";

const CropProductions = (): ReactElement => {
  const {
    cropPerformanceApiResponse,
    answerApiResponse,
    cropPerformanceApiLoading,
    answerApiLoading,
  } = useContext(ManagementPracticeDataSetContext);
  const { cropperformance } = cropPerformanceApiResponse || {};

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Yield irrigated",
      graph: <YieldIrrigated growersDetails={cropperformance} />,
      loading: cropPerformanceApiLoading,
    },
    {
      title: "Yield and fertilizer",
      graph: <YieldAndFertilizer growersDetails={cropperformance} />,
      loading: cropPerformanceApiLoading,
    },
    {
      title: "Average fertilizer",
      graph: <FertilizerAverage growersDetails={answerApiResponse} />,
      loading: answerApiLoading,
    },
  ];

  const eventName = `${APP_MODULES.MANAGEMENT_PRACTICE}_${APP_MODULES.CROP_PRODUCTIONS}`;
  useCallAmplitudeEvent(eventName);

  return (
    <div>
      <Row gutter={16} className="overview-context">
        {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
          return GetGraphComponent({ ...graphDetails, crop: true }, index);
        })}
      </Row>
      <CropProductionAverages
        loading={cropPerformanceApiLoading}
        cropDetails={cropPerformanceApiResponse}
      />
    </div>
  );
};

export default CropProductions;
