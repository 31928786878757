import { Card } from "antd";
import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { neutral } from "../../../../constants/colorsPallete";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import {
  type ChartDataset,
  type GenericDataType,
  type SoilHealthChartDataSet,
  type SoilHealthDataItem,
} from "../types";
import {
  calculateGraphDistribution,
  divideArrayByGraphColumnRange,
  formatLabel,
  soilHealthData,
} from "../utils";

const SoilHealth = ({
  soilDetails,
  graphLabel = "Soil Health",
}: {
  soilDetails: GenericDataType;
  graphLabel?: string;
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck >= 1 ? "start" : "end";
          }
          return "end";
        },
        color: (context) => {
          if (context.datasetIndex < 6 && context.dataset.data[0] !== 0) {
            return "white";
          } else {
            return "black";
          }
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          lineWidth: 1,
          color: neutral["30"],
        },
        title: {
          display: true,
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
          font: {
            size: 16,
          },
          color: neutral["50"],
        },
      },
    },
  };

  const { data, labels } = soilDetails;
  const graphColumnRanges = calculateGraphDistribution(labels.length);
  const listOfLabels: string[][] = divideArrayByGraphColumnRange(
    labels,
    graphColumnRanges
  );
  const listOfDataCount: number[][] = divideArrayByGraphColumnRange(
    data,
    graphColumnRanges
  );

  const soilHealthDataSet = [];
  for (let index = 0; index < listOfLabels.length; index++) {
    soilHealthDataSet.push(
      soilHealthData(listOfDataCount[index], listOfLabels[index])
    );
  }

  const createSoilHealthDataSet = (
    dataSet: SoilHealthDataItem[]
  ): SoilHealthChartDataSet => ({
    labels: ["Soil health practices"],
    datasets: dataSet?.map(({ label, count, backgroundColor }) => ({
      label: formatLabel(label),
      data: [count],
      backgroundColor: [backgroundColor],
      barPercentage: 0.8,
      categoryPercentage: 1.0,
      hoverBackgroundColor: [backgroundColor],
    })),
  });

  const actualDataSets = [];
  for (const element of soilHealthDataSet) {
    actualDataSets.push(createSoilHealthDataSet(element));
  }
  const generateLabels = (dataSets: ChartDataset[]): ReactElement => {
    return (
      <div>
        <ul className="legend">
          {dataSets.map((label) => (
            <li key={label.label}>
              <div
                className="legend-color-box"
                style={{ backgroundColor: label.backgroundColor[0] }}
              ></div>
              <span>{label.label}</span>
            </li>
          ))}
          <></>
        </ul>
      </div>
    );
  };
  const graphLabels: ReactElement[] = [];
  for (const element of actualDataSets) {
    graphLabels.push(generateLabels(element.datasets));
  }
  return (
    <>
      {actualDataSets.map((dataSet, index) => (
        <Card key={index} className="overview-context-farms soil-health">
          <h3>{graphLabel}</h3>
          <div>
            <VerticalBarChart options={options} data={dataSet} />
            <div id={`soil_health_${index}`}>{graphLabels[index]}</div>
          </div>
        </Card>
      ))}
    </>
  );
};

export default SoilHealth;
