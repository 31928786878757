import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "syngenta-digital-cropwise-react-ui-kit";
import "./sidebar.less";

interface route {
  name: string;
  path: string;
}

interface SidebarProps {
  onMenuItemClick: () => void;
}

export const SidebarMenu: React.FC<SidebarProps> = ({ onMenuItemClick }) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname.split("/");

  const isAssessment = window.location.pathname.toString().includes("reports")
    ? "REPORTS"
    : "";

  const [active, setActive] = useState(isAssessment);

  const currentPage = pathName[pathName.length - 1];
  const routes: Record<string, route> = {
    REPORTS_OVERVIEW_AND_NAVIGATION: {
      name: "Reports overview and navigation",
      path: "/partners",
    },
  };
  const routesMap = Object.keys(routes);

  return (
    <Sidebar
      data-cy="sidebar"
      theme="dark"
      selectedKeys={[active || currentPage.toUpperCase()]}
      collapsedMenu={true}
      // @ts-expect-error suppressing below error
      menuItems={routesMap.map((route) => ({
        key: route,
        label: routes[route].name,
        icon: (
          <img
            className="sidebar-icon active"
            alt={route.toLowerCase()}
            src={require(`../../assets/images/${route
              .toLowerCase()
              .replaceAll("_", "-")}.svg`)}
          />
        ),
        onClick: async (e: any) => {
          setActive(route);
          navigate(`/app${routes[route].path}`);
          if (routes[route].name === "Reports overview and navigation") {
            onMenuItemClick();
          }
        },
      }))}
    />
  );
};
