import { type ScoreStatus } from "./types";

// DriverRankedByScore
const scoreStatus: ScoreStatus = {
  High: { highest: 3, lowest: 2.5 },
  Medium: { highest: 2.5, lowest: 1.5 },
  Basic: { highest: 1.5, lowest: 0.5 },
  Essential: { highest: 0.5, lowest: 0 },
};

export const getStatus = (score: number): string => {
  for (const status in scoreStatus) {
    if (
      score <= scoreStatus[status].highest &&
      score >= scoreStatus[status].lowest
    ) {
      return status;
    }
  }
  return "unknown";
};
