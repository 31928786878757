import type { ColumnsType } from "antd/es/table";
import { useContext, type ReactElement } from "react";
import { useParams } from "react-router-dom";
import Table from "../../../../shared/components/Table";
import useAxios from "../../../../shared/custom-hooks/useAxios";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getPracticeImprovementOpportunities } from "../../../../transformer/summary/practice-improvement-opportunities";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { OutcomeDataSetContext } from "../../OutcomeDetails";
import { type OpportunitiesColumnDataType } from "../types";

const PracticeImprovementOpportunities = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);
  const columns: ColumnsType<OpportunitiesColumnDataType> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Practices</div>,
      dataIndex: "practice",
      key: "practice",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Higher scorers</div>,
      dataIndex: "higherPercentage",
      key: "higherPercentage",
      width: "10%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Lower scorers</div>,
      dataIndex: "lowerPercentage",
      key: "lowerPercentage",
      width: "10%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Difference</div>,
      dataIndex: "difference",
      key: "difference",
      width: "10%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Total %</div>,
      dataIndex: "totalPercentage",
      key: "totalPercentage",
      width: "10%",
    },
  ];
  const { loading, response } = useAxios(
    `/outcome/summary/practice/${String(partnerId)}/${String(cropYear)}`
  );

  const practiceImprovementOpportunitiesTableData =
    getPracticeImprovementOpportunities(
      outcomeOverviewContext,
      response?.practices
    );
  const eventName = `${APP_MODULES.SUMMARY}_${APP_MODULES.PRACTICE_IMPROVEMENT_OPPORTUNITIES}`;
  useCallAmplitudeEvent(eventName);

  return (
    <>
      <div className="container-title">
        <p className="title">Practice improvement opportunities</p>
        <Table
          loading={loading}
          columns={columns}
          dataSource={practiceImprovementOpportunitiesTableData}
        />
      </div>
    </>
  );
};
export default PracticeImprovementOpportunities;
