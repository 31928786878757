import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { neutral, purple } from "../../../../constants/colorsPallete";
import ScatterChart from "../../../../shared/components/ScatterChart";
import { type CropProductionYieldDataType } from "../types";

const YieldAndFertilizer = ({
  growersDetails,
}: {
  growersDetails: CropProductionYieldDataType[];
}): ReactElement => {
  const options: ChartOptions<"scatter"> = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    aspectRatio: 1.5,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Yield",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 100,
          color: neutral["50"],
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        title: {
          text: "Nitrogen fertilizer",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 100,
          color: neutral["50"],
        },
      },
    },
  };

  const cropProductionsYieldAndFertilizerDataSet = {
    datasets: [
      {
        label: "Data",
        data: growersDetails?.map((crop) => ({
          x: parseInt(crop.yield),
          y: parseInt(crop.nitrogen),
        })),
        backgroundColor: purple["50"],
        borderColor: neutral["100"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <ScatterChart
      options={options}
      data={cropProductionsYieldAndFertilizerDataSet}
    />
  );
};

export default YieldAndFertilizer;
