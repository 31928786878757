import { teal } from "../../../constants/colorsPallete";
import { type ScoreStatus } from "./types";

export const scoreStatus: ScoreStatus = {
  basic: { highest: 1.5, lowest: 0.5 },
  essential: { highest: 0.5, lowest: 0 },
  medium: { highest: 2.5, lowest: 1.5 },
  high: { highest: 3, lowest: 2.5 },
};

export const generateOutcomesDriversSequence = (n: number): number[] => {
  const sequence = [];
  let remaining = n;

  while (remaining > 0) {
    if (
      remaining >= 3 &&
      remaining !== 4 &&
      remaining !== 2 &&
      remaining !== 1
    ) {
      sequence.unshift(3);
      remaining -= 3;
    } else if (remaining >= 2 && remaining !== 1) {
      sequence.unshift(2);
      remaining -= 2;
    } else {
      sequence.unshift(1);
      remaining -= 1;
    }
  }

  return sequence.sort((a, b) => a - b);
};

export const backgroundGenerate = (sequence: number[]): string[] => {
  const fixedColors = [teal["40"], teal["30"], teal["20"], teal["10"]];
  const data: string[] = [];
  let colorIndex = 0;

  for (let i = 0; i < sequence?.length; i++) {
    for (let j = 0; j < sequence[i]; j++) {
      data.push(fixedColors[colorIndex]);
    }
    colorIndex = (colorIndex + 1) % fixedColors.length;
  }
  return data;
};
