import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState, type ReactElement } from "react";
import Table from "../../../../shared/components/Table";
import "../../../style.less";
import {
  type CropProductionData,
  type CropProductionTableColumnDataType,
} from "../types";
import { calculateCropProductionAverages } from "../utils";

const CropProductionAverages = ({
  cropDetails,
  loading,
}: {
  cropDetails: CropProductionData;
  loading: boolean;
}): ReactElement => {
  const [tableData, setTableData] = useState<
    CropProductionTableColumnDataType[]
  >([]);

  const columns: ColumnsType<CropProductionTableColumnDataType> = [
    {
      title: <div>Average of yield</div>,
      dataIndex: "average_of_yield",
      key: "average_of_yield",
      sorter: (a, b) => a.average_of_yield.localeCompare(b.average_of_yield),
      sortDirections: ["descend", "ascend"],
      width: "50%",
    },
    {
      title: <div>Nitrogen</div>,
      dataIndex: "nitrogen",
      key: "nitrogen",
      sorter: (a, b) => a.nitrogen.localeCompare(b.nitrogen),
      sortDirections: ["descend", "ascend"],
    },
  ];

  useEffect(() => {
    if (loading) {
      return;
    }
    const averages = calculateCropProductionAverages(cropDetails);
    setTableData(averages);
  }, [cropDetails]);

  return (
    <div className="driver-table-main crop-production">
      <p className="title">Crop production averages</p>
      <Table columns={columns} loading={loading} dataSource={tableData} />
    </div>
  );
};

export default CropProductionAverages;
