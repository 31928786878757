import { blue } from "../../constants/colorsPallete";
import { SAI_INDICATOR_LEVELS } from "../constants";

export const getFarmersEssentialQuestions = (saiData: any): any => {
  const { data, totalFarmers, metadata } = saiData;
  const essentialList = metadata.filter(
    (data: any) => data.type === SAI_INDICATOR_LEVELS.ESSENTIAL
  )[0]?.codes;
  const countMap: any = {};
  essentialList.forEach((key: string) => {
    countMap[key] = 0;
  });

  data.forEach((plan: any) => {
    plan.fsaScore.essential?.codes.forEach((score: any) => {
      countMap[score]++;
    });
  });
  const labels: string[] = [];
  const dataset: any = [];
  Object.keys(countMap).forEach((key: string) => {
    labels.push(key.replace("FSA", ""));
    const percentage = parseFloat(
      ((countMap[key] / totalFarmers) * 100).toFixed(2)
    );
    dataset.push(percentage);
  });
  return {
    labels,
    datasets: [
      {
        data: dataset,
        label: "Question",
        backgroundColor: blue["40"],
      },
    ],
  };
};
