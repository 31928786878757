import React, { type FC, type ReactNode } from "react";
import "./style.less";

interface LeftNavigatorPropTypes {
  children: ReactNode;
}

export const LeftNavigator: FC<LeftNavigatorPropTypes> = ({
  children,
}): JSX.Element => {
  return (
    <div className={"layout-container"}>
      <div className="card-wrapper">{children}</div>
    </div>
  );
};
