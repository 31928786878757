import { type ChartOptions } from "chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { type ReactElement } from "react";
import { Pie } from "react-chartjs-2";
import { neutral, purple } from "../../../../constants/colorsPallete";
import { type GenericDataType } from "../types";

Chart.register(ChartDataLabels);
export const calculateDataLabelPosition = (
  angle: number,
  x: number,
  y: number,
  outerRadius: number
): { dataLabelX: number; dataLabelY: number } => {
  const radius: number = Number(outerRadius) + 10;
  const centerX: number = x;
  const centerY: number = y;
  const dataLabelX = centerX + (radius + 10) * Math.cos(angle);
  const dataLabelY = centerY + (radius + 10) * Math.sin(angle);
  return { dataLabelX, dataLabelY };
};
export const drawLinePlugin = {
  id: "drawLinePlugin",
  afterDatasetsDraw: (chart: any) => {
    if (chart.config.type !== "pie") {
      return;
    }
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset: any, datasetIndex: number) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      if (!meta?.data || meta.data.length === 0) {
        return;
      }
      meta.data.forEach((element: any, index: number) => {
        const value = chart.data.datasets[datasetIndex].data[index];
        if (value <= 0) {
          return;
        }
        const {
          x,
          y,
          startAngle,
          endAngle,
          outerRadius,
        }: {
          x: number;
          y: number;
          startAngle: number;
          endAngle: number;
          outerRadius: number;
        } = element.getProps(
          ["x", "y", "startAngle", "endAngle", "outerRadius"],
          true
        );
        const angle: number =
          Number(startAngle) + (Number(endAngle) - Number(startAngle)) / 2;
        const startX: number =
          Number(x) + Math.cos(angle) * Number(outerRadius);
        const startY: number = Number(y) + Math.sin(angle) * outerRadius;
        const {
          dataLabelX,
          dataLabelY,
        }: { dataLabelX: number; dataLabelY: number } =
          calculateDataLabelPosition(angle, x, y, outerRadius);
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(dataLabelX, dataLabelY);
        ctx.strokeStyle = "#D3D3D3";
        ctx.lineWidth = 2;
        ctx.stroke();
      });
    });
    ctx.restore();
  },
};
Chart.register(drawLinePlugin);

const LivestockSpecies = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const { data, labels } = growersDetails;
  const speciesTotal = data.reduce(
    (total: number, value: number) => value + total,
    0
  );
  const options: ChartOptions<"pie"> = {
    responsive: true,
    animation: false,
    aspectRatio: 2,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        offset: 20,
        font: {
          size: 12,
        },
        color: neutral["100"],
        formatter: (value) => {
          if (value <= 0) {
            return null;
          }
          return `${String(value)} (${((value / speciesTotal) * 100).toFixed(
            2
          )}%)`;
        },
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          color: neutral["70"],
          font: {
            size: 12,
          },
          boxHeight: 8,
          boxWidth: 8,
        },
      },
    },
    layout: {
      padding: {
        bottom: 40,
        top: 30,
      },
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [purple["40"], purple["30"]],
        hoverBackgroundColor: [purple["40"], purple["30"]],
        borderWidth: 0,
      },
    ],
  };
  return <Pie data={chartData} options={options} />;
};
export default LivestockSpecies;
