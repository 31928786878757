import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { neutral, purple } from "../../../../constants/colorsPallete";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { type GenericDataType } from "../types";

const ContinuousNoTillOrDirectDrill = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const drillPercentage: number[] = Object.values(growersDetails);
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: (value) => (value >= 1 ? value : 0),
        color: "black",
      },
      // tooltip: {
      //   callbacks: {
      //     title: (context) => {
      //       console.log(labels, context);

      //       // return labels[context?.[0]?.dataIndex || 0];
      //     },
      //   },
      // },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Continuous no-till or direct drill %",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        border: { dash: [10, 10] },
        ticks: {
          stepSize: 20,
          color: neutral["50"],
        },
      },
      y: {
        beginAtZero: true,
        border: { dash: [10, 10] },
        title: {
          display: true,
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 20,
          color: neutral["50"],
        },
        max: Math.max(...drillPercentage) + 1,
      },
    },
  };

  const continuousNoTillOrDirectDrillDataSet = {
    labels: Object.keys(growersDetails),
    datasets: [
      {
        label: "Growers",
        data: drillPercentage,
        backgroundColor: purple["50"],
        hoverBackgroundColor: purple["50"],
        borderWidth: 0.2,
        barThickness: 5,
      },
    ],
  };

  return (
    <VerticalBarChart
      options={options}
      data={continuousNoTillOrDirectDrillDataSet}
      height={180}
    />
  );
};

export default ContinuousNoTillOrDirectDrill;
