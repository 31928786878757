import React, { type ReactElement } from "react";
import "../../../shared/components/style.less";
import { Card, Col, Row } from "antd";
interface outcomeStatusDataInterface {
  outcomeName: string;
  status: string;
}

const OutcomeStatuses = ({
  outcomeStatusData,
}: {
  outcomeStatusData: outcomeStatusDataInterface[];
}): ReactElement => {
  return (
    <Card className="outcome-status-main">
      <div className="outcome-status-container">
        <Row gutter={16} wrap={true}>
          {outcomeStatusData.map(
            (item: outcomeStatusDataInterface, index: number) => (
              <Col flex="1" key={index}>
                <div className="outcome-status-item">
                  <span className="status font-16">{item.status}</span>
                  <span className="name font-16">{item.outcomeName}</span>
                </div>
              </Col>
            )
          )}
        </Row>
      </div>
    </Card>
  );
};
export default OutcomeStatuses;
