import { useContext, useEffect, useState, type ReactElement } from "react";
import { OverviewDataSetContext } from "..";
import WorldGeoMapChart from "../../../../shared/components/WorldGeoMapChart";
import useAxios from "../../../../shared/custom-hooks/useAxios";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { getTotalAcersByCountry } from "../utils";

const AcresByCity = (): ReactElement => {
  const [chartData, setChartData] = useState<Record<string, number>>({});
  const { overviewData } = useContext(OverviewDataSetContext);
  const { response: countries } = useAxios("/country");

  useEffect(() => {
    if (overviewData?.plans?.length && countries?.length) {
      setChartData(getTotalAcersByCountry(overviewData, countries));
    }
  }, [overviewData, countries]);

  const eventName = `${APP_MODULES.OVERVIEW}_${APP_MODULES.ACRES_BY_COUNTRY}`;
  useCallAmplitudeEvent(eventName);

  return (
    <div>
      <WorldGeoMapChart chartData={chartData} />
    </div>
  );
};

export default AcresByCity;
