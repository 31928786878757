import { Row } from "antd";
import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import React, { useContext, type ReactElement } from "react";
import { ManagementPracticeDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { GetGraphComponent } from "../getGraphComponent";
import { type GraphDetailsType } from "../types";
import EnvironmentalStewardship from "./EnvironmentalStewardship";
import LongTermSystemResilience from "./LongTermSystemResilience";
import NitrogenEfficiency from "./NitrogenEfficiency";
import NitrogenFertilizerDistribution from "./NitrogenFertilizerDistribution";
import OptimalProduction from "./OptimalProduction";

const NutrientManagement = (): ReactElement => {
  const {
    cropPerformanceApiResponse,
    cropPerformanceApiLoading,
    answerApiLoading,
    answerApiTransformedData,
  } = useContext(ManagementPracticeDataSetContext);
  const { cropperformance } = cropPerformanceApiResponse || {};

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "center",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "top" : "center",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? neutral["100"] : "black";
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? neutral["100"] : "black";
          }
          return "black";
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Nutrient management practices",
          color: neutral["70"],
          font: {
            size: 16,
          },
          padding: {
            top: 20,
          },
        },
        ticks: {
          font: {
            size: 10,
          },
          color: neutral["50"],
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
          color: neutral["50"],
        },
      },
    },
  };

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Nitrogen use efficiency",
      graph: <NitrogenEfficiency growersDetails={cropperformance} />,
      loading: cropPerformanceApiLoading,
    },
    {
      title: "Fertilizer distribution - nitrogen",
      graph: (
        <NitrogenFertilizerDistribution growersDetails={cropperformance} />
      ),
      loading: cropPerformanceApiLoading,
    },
    {
      title: "Long term system resilience",
      graph: (
        <LongTermSystemResilience
          growersDetails={
            answerApiTransformedData?.["nutrient-long-term-system-resilience"]
          }
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Environmental stewardship",
      graph: (
        <EnvironmentalStewardship
          growersDetails={
            answerApiTransformedData?.["nutrient-environmental-stewardship"]
          }
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Optimal production",
      graph: (
        <OptimalProduction
          growersDetails={
            answerApiTransformedData?.["nutrient-optimal-production"]
          }
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
  ];

  const eventName = `${APP_MODULES.MANAGEMENT_PRACTICE}_${APP_MODULES.NUTRIENT_MANAGEMENT}`;
  useCallAmplitudeEvent(eventName);

  return (
    <Row gutter={16} className="overview-context">
      {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
        return GetGraphComponent({ ...graphDetails }, index);
      })}
    </Row>
  );
};

export default NutrientManagement;
