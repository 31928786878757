import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import React, { type ReactElement } from "react";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { neutral, purple } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { type GenericDataType } from "../types";
import { getChartData, livingRootLablesSet } from "../utils";

const LivingRoot = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck >= 1 ? "start" : "right";
          }
          return "right";
        },
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
      tooltip: {
        callbacks: {
          title: (context) => labels[context[0]?.dataIndex || 0],
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 3,
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          text: "Days with living root",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          color: neutral["50"],
          padding: 0,
          font: {
            size: 12,
          },
        },
      },
    },
  };
  const { data, labels } = growersDetails;

  // TODO: check below function again
  const modifiedLables = livingRootLablesSet(labels);
  const chartData = getChartData(labels, data, purple["50"]);
  chartData.labels = modifiedLables;
  return <HorizontalBarChart options={options} data={chartData} height={350} />;
};

export default LivingRoot;
