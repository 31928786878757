import { Col, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { useContext, type ReactElement } from "react";
import { SaiIndicatorDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { InlineLoader } from "../../../../shared/components/Loader";
import Table from "../../../../shared/components/Table";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import "../../../style.less";
import { SaiPerformanceTableMockData } from "../constants";
import { type SaiPerformanceTableData } from "../types";

const SaiPerformance = (): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "end" : "start",
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IChoroplethDataPoint
            | IBubbleMapDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
          color: neutral["70"],
        },
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const columns: ColumnsType<SaiPerformanceTableData> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Performance</div>,
      dataIndex: "performance",
      key: "performance",
      align: "center",
      width: "20%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Threshold</div>,
      dataIndex: "threshold",
      key: "threshold",
      align: "left",
    },
  ];
  const eventName = `${APP_MODULES.SAI_INDICATOR}_${APP_MODULES.SAI_PERFORMANCE}`;
  useCallAmplitudeEvent(eventName);

  const {
    saiIndicator: { saiPerformance },
    loading,
  } = useContext(SaiIndicatorDataSetContext);
  return (
    <div className="sai-performance">
      <Row gutter={16} className="overview-context">
        <Col span={12} className="ant-card-bordered">
          <p className="title">SAI performance</p>
          {loading ? (
            <InlineLoader
              text="Loading graph..."
              top="10vh"
              position="relative"
            />
          ) : (
            <VerticalBarChart options={options} data={saiPerformance} />
          )}
        </Col>
        <Col span={12}>
          <div>
            <Table
              columns={columns}
              dataSource={SaiPerformanceTableMockData}
              outerBorderOnly={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SaiPerformance;
