import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import React, { type ReactElement } from "react";
import { neutral, purple } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { type CropProductionYieldDataType } from "../types";
import {
  calculateAndGetChartData,
  calculateYieldIrrigatedFrequency,
} from "../utils";

const YieldIrrigated = ({
  growersDetails,
}: {
  growersDetails: CropProductionYieldDataType[];
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
      datalabels: {
        anchor: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck >= 3 ? "end" : "center";
          }
          return "center";
        },
        align: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck === 0
              ? "end"
              : valueToCheck >= 3
              ? "start"
              : "center";
          }
          return "center";
        },
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Frequency",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 2,
          minRotation: 360,
          color: neutral["50"],
        },
      },
      y: {
        title: {
          text: "Yield",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: neutral["50"],
        },
      },
    },
  };

  const cropProductionsYieldIrrigatedDataSet = calculateAndGetChartData(
    "Total Area",
    purple["50"],
    "yield",
    growersDetails,
    calculateYieldIrrigatedFrequency
  );

  return (
    <HorizontalBarChart
      options={options}
      data={cropProductionsYieldIrrigatedDataSet}
    />
  );
};

export default YieldIrrigated;
