import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import React, { type ReactElement } from "react";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { neutral, purple } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { type CropProductionYieldDataType } from "../types";
import { calculateNitrogenAmountCount, calculateRanges } from "../utils";

const NitrogenFertilizerDistribution = ({
  growersDetails,
}: {
  growersDetails: CropProductionYieldDataType[];
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        align: (context) =>
          context.dataset?.data[context.dataIndex] === 1 ? "center" : "right",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 2,
          color: neutral["50"],
        },
      },
      y: {
        title: {
          text: "Nitrogen",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: neutral["50"],
        },
      },
    },
  };
  const totalNitrogenAmountData: number[] = growersDetails.map(
    (grower: CropProductionYieldDataType) => parseInt(grower?.nitrogen)
  );

  const ranges = calculateRanges(totalNitrogenAmountData);
  const isSingleGrower = growersDetails.length === 1;
  const growerNitrogenAmoutLevelCount: number[] = calculateNitrogenAmountCount(
    ranges,
    growersDetails
  );

  const growerNitrogenAmoutDataSet = {
    labels: isSingleGrower ? [totalNitrogenAmountData[0].toFixed(2)] : ranges,
    datasets: [
      {
        label: "Total Area",
        data: isSingleGrower ? [1] : growerNitrogenAmoutLevelCount,
        backgroundColor: purple["50"],
        hoverBackgroundColor: purple["50"],
      },
    ],
  };
  return (
    <HorizontalBarChart
      options={options}
      data={growerNitrogenAmoutDataSet}
      height={260}
    />
  );
};

export default NitrogenFertilizerDistribution;
