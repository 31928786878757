export const SaiPerformanceTableMockData = [
  {
    performance: "Bronze",
    threshold:
      "Compliance to 100% ’Essential’ questions and a minimum of 50% ’Intermediate’ questions.",
  },
  {
    performance: "Silver",
    threshold:
      "Compliance to 100% ’Essential’ questions, 75% ’Intermediate’ questions and more than 50% ’Advanced questions’.",
  },
  {
    performance: "Gold",
    threshold:
      "Compliance to 100% ’Essential’ questions, 100% ’Intermediate’ questions and a minimum of 75% ’Advanced questions’.",
  },
  {
    performance: "Not yet bronze",
    threshold:
      "Indicates that the level of performance does not meet the bronze threshold yet.",
  },
];
