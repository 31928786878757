import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { blue } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";

const FarmersByPercentageOfQuestions = ({
  farmerByQuestionData,
  options,
  graphLabel,
}: {
  farmerByQuestionData: Array<{ range: number; count: number }>;
  options: ChartOptions<"bar">;
  graphLabel: string;
}): ReactElement => {
  const rangeAndCount = calculateRange(farmerByQuestionData);
  const essentialDataSet = {
    labels: rangeAndCount.rangeInterval,
    datasets: [
      {
        label: graphLabel,
        data: rangeAndCount.rangeCount,
        backgroundColor: blue["50"],
      },
    ],
  };

  return (
    <HorizontalBarChart
      options={options}
      data={essentialDataSet}
      height={430}
    />
  );
};

export default FarmersByPercentageOfQuestions;

function calculateRange(data: any): Record<string, any> {
  const intervals: Record<string, number> = {};
  intervals["0-10"] = 0;
  for (let i = 11; i <= 100; i += 10) {
    intervals[`${i}-${i + 9}`] = 0;
  }
  data.forEach((item: { range: number; count: number }) => {
    const intervalStart = Math.floor((item.range - 1) / 10) * 10 + 1;
    if (item.range <= 10) {
      intervals["0-10"] += item.count;
    } else if (intervalStart <= 90) {
      intervals[`${intervalStart}-${intervalStart - 1 + 10}`] += item.count;
    } else {
      intervals["91-100"] += item.count;
    }
  });

  const rangeInterval: string[] = [];
  const rangeCount: number[] = [];
  for (const interval in intervals) {
    rangeInterval.push(interval);
    rangeCount.push(intervals[interval]);
  }

  return {
    rangeInterval,
    rangeCount,
  };
}
