import { Row } from "antd";
import React, { useContext, type ReactElement } from "react";
import { ManagementPracticeDataSetContext } from "..";
import { GetGraphComponent } from "../getGraphComponent";
import { type GraphDetailsType } from "../types";
import LivestockGraze from "./LivestockGraze";
import LivestockSpecies from "./LivestockSpecies";
import TillagePercentage from "./TillagePercentage";
import ContinuousNoTillOrDirectDrill from "./ContinuousNoTillOrDirectDrill";

const SoilHealth = (): ReactElement => {
  const { answerApiLoading, answerApiTransformedData } = useContext(
    ManagementPracticeDataSetContext
  );

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Continuous no-till or direct drill %",
      graph: (
        <ContinuousNoTillOrDirectDrill
          growersDetails={answerApiTransformedData["continuous-no-till"]}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Tillage percentage",
      graph: (
        <TillagePercentage growersDetails={answerApiTransformedData.tillage} />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Livestock Grazing",
      graph: (
        <LivestockGraze
          growersDetails={answerApiTransformedData["soil-livestock-graze"]}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Livestock species grazing on fields",
      graph: (
        <LivestockSpecies
          growersDetails={answerApiTransformedData["soil-livestock-species"]}
        />
      ),
      loading: answerApiLoading,
    },
  ];

  return (
    <Row gutter={16} className="overview-context">
      {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
        return GetGraphComponent({ ...graphDetails }, index);
      })}
    </Row>
  );
};

export default SoilHealth;
