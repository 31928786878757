import { type ReactElement } from "react";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import CommonOutcomesModule from "../CommonOutcomesModule";

const BiodiversityAndHabitatLevels = (): ReactElement => {
  const eventName = `${APP_MODULES.OUTCOMES}_${APP_MODULES.BIODIVERSITY_AND_HABITAT}`;
  useCallAmplitudeEvent(eventName);

  return (
    <CommonOutcomesModule
      outcomeKey="1.0.0"
      outcomeName="Biodiversity and habitat levels"
    />
  );
};

export default BiodiversityAndHabitatLevels;
