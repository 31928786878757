const transformData = (planType: any, percentage: any): any => {
  if (planType.length > 0) {
    let exists = 0;
    for (const edata of planType) {
      if (edata.range === percentage) {
        exists = 1;
        edata.count = parseInt(edata.count) + 1;
      }
    }
    if (exists === 0) {
      planType.push({
        range: percentage,
        count: 1,
      });
    }
  } else {
    planType.push({
      range: percentage,
      count: 1,
    });
  }
};

export const getSaiFarmersQuestions = (saiData: any): any => {
  const farmersCount: any = {
    essential: [],
    intermediate: [],
    advanced: [],
  };

  const { data, totalFarmers } = saiData;
  for (const plan of data) {
    const score = plan.fsaScore;
    const ePercentage = score.essential?.percentage
      ? Math.round(score.essential.percentage)
      : 0;

    transformData(farmersCount.essential, ePercentage);

    const iPercentage = score.intermediate?.percentage
      ? Math.round(score.intermediate.percentage)
      : 0;
    transformData(farmersCount.intermediate, iPercentage);

    const advPercentage = score.advanced?.percentage
      ? Math.round(score.advanced.percentage)
      : 0;
    transformData(farmersCount.advanced, advPercentage);
  }
  return {
    totalFarmers,
    farmersCount,
  };
};
