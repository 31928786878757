import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { neutral } from "../../../../constants/colorsPallete";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getOverallLeadershipLevel } from "../../../../transformer/summary/overall-leadership-level";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { OutcomeDataSetContext } from "../../OutcomeDetails";
import { type OutcomeDataSet } from "../../types";

const OverAllLeaderShipLevel = (): ReactElement => {
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);
  const [leaderShipData, setLeaderShipData] = useState<OutcomeDataSet>({
    datasets: [],
    labels: [],
  });

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "top" : "start",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IChoroplethDataPoint
            | IBubbleMapDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "#fff" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Overall Score",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: neutral["50"],
          font: {
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
          color: neutral[50],
          font: {
            size: 16,
          },
        },
      },
    },
  };
  const eventName = `${APP_MODULES.SUMMARY}_${APP_MODULES.OVERALL_LEADERSHIP_LEVELS}`;
  useCallAmplitudeEvent(eventName);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const overallLeadershipLevel = getOverallLeadershipLevel(
        outcomeOverviewContext
      );
      setLeaderShipData(overallLeadershipLevel);
    }
    void fetchData();
  }, []);

  return (
    <div className="container-title">
      <p className="title">Overall leadership levels</p>
      <VerticalBarChart options={options} data={leaderShipData} />
    </div>
  );
};
export default OverAllLeaderShipLevel;
