import _ from "lodash";
import { LOCALE_EN } from "../constants";

export const getPracticeImprovementOpportunities = (
  data: any,
  pratices: any
): any => {
  const { userActivityScore, totalFarmers, languages } = data;
  const farmerHigherScorers = new Set();
  const farmerLowerScorers = new Set();
  const { farmersOverallScore, overallScore } =
    getFarmersMap(userActivityScore);
  const overallAvg = overallScore / totalFarmers;

  farmersOverallScore.forEach((val: any, key: string) => {
    if (val >= overallAvg) {
      farmerHigherScorers.add(key);
    } else {
      farmerLowerScorers.add(key);
    }
  });
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];

  const practiceArr: any = [];
  _.chain(pratices)
    .groupBy((item: any) => {
      return item.soaId;
    })
    .forEach((val, key) => {
      const scores = {
        higher: 0,
        lower: 0,
        total: 0,
      };
      let label: string = "";
      val.forEach((practice: any) => {
        if (!label && practice.language === locale) {
          label = practice.labelName;
        }
        if (practice.answer) {
          if (farmerHigherScorers.has(practice.planId)) {
            scores.higher++;
          } else {
            scores.lower++;
          }
          scores.total++;
        }
      });

      const calculatePercentage = (
        numerator: number,
        denominator: number
      ): number => {
        const percentage = (numerator / denominator) * 100;
        return Math.round(denominator ? parseFloat(percentage.toFixed(2)) : 0);
      };

      const higherPercentage = calculatePercentage(
        scores.higher,
        farmerHigherScorers.size
      );
      const lowerPercentage = calculatePercentage(
        scores.lower,
        farmerLowerScorers.size
      );

      const totalPercentage = Math.round(
        parseFloat(((scores.total / totalFarmers) * 100).toFixed(2))
      );
      if (key === "CO-4" && totalPercentage > 50) {
        label = "Conservation tillage";
      }
      practiceArr.push({
        practice: label,
        higherPercentage,
        lowerPercentage,
        difference: parseFloat(
          Math.abs(higherPercentage - lowerPercentage).toFixed(2)
        ),
        totalPercentage,
      });
    })
    .value();
  practiceArr.sort((a: any, b: any) => b.difference - a.difference);
  return practiceArr.slice(0, 5);
};

const getFarmersMap = (userActivityScore: any): any => {
  const farmerScoreMap = new Map();
  const farmersOverallScore = new Map();
  let overallScore = 0;
  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return `${item.planId as string}::${item.outcomeNumber as string}`;
    })
    .forEach((value, key) => {
      const score = value.reduce(
        (acc: number, val: any) => acc + parseFloat(val.score),
        0
      );
      const avgScore = parseFloat((score / value.length).toFixed(2));
      const planId = key.split("::")[0];
      let arr = farmerScoreMap.get(planId);
      if (!arr) {
        arr = [];
      }
      arr.push(avgScore);
      farmerScoreMap.set(planId, arr);
    })
    .value();

  farmerScoreMap.forEach((val: any, key: string) => {
    const avgScore = parseFloat(_.mean(val).toFixed(2));
    overallScore += avgScore;
    farmersOverallScore.set(key, avgScore);
  });
  return { farmersOverallScore, overallScore };
};
