import AddChart from "../assets/images/addchart.svg";
import Analytics from "../assets/images/analytics.svg";
import BarChart from "../assets/images/bar-chart.svg";
import BiodiversityAndHabitat from "../assets/images/biodiversity-habitat.svg";
import Crop from "../assets/images/crop-cycle.svg";
import Book from "../assets/images/field-book.svg";
import FieldMonitoring from "../assets/images/field-monitoring.svg";
import Field from "../assets/images/field.svg";
import Group from "../assets/images/group.svg";
import HealthAndSafety from "../assets/images/health-and-safety.svg";
import LeaderBoard from "../assets/images/leaderboard.svg";
import Map from "../assets/images/map.svg";
import Person from "../assets/images/person.svg";
import PestControl from "../assets/images/pest-control.svg";
import OptimalProduction from "../assets/images/productivity-map.svg";
import Score from "../assets/images/score.svg";
import Search from "../assets/images/search.svg";
import Seeds from "../assets/images/seeds.svg";
import ShowChart from "../assets/images/show-chart.svg";
import Soil from "../assets/images/soil.svg";
import Umbrella from "../assets/images/umbrella.svg";
import Water from "../assets/images/water.svg";

export interface TabContent {
  title: string;
  icon: string;
  route: string;
}

export interface TabDetails {
  tabName: string;
  icon: string;
  key: string;
  content: TabContent[];
}
export const TAB_SECTION_TITLES: TabDetails[] = [
  {
    tabName: "Overview",
    icon: Search,
    key: "overview",
    content: [
      {
        title: "Context",
        icon: Field,
        route: "overview/context",
      },
      {
        title: "Acres by country",
        icon: Map,
        route: "overview/acres-by-city",
      },
    ],
  },
  {
    icon: Book,
    tabName: "Summary",
    key: "summary",
    content: [
      {
        title: "Outcome overview",
        icon: AddChart,
        route: "summary/outcome-overview",
      },
      {
        title: "Drivers ranked by score",
        icon: Score,
        route: "summary/drivers-ranked-by-score",
      },
      {
        title: "Opportunities to improve performance",
        icon: ShowChart,
        route: "summary/opportunities-to-improve-performance",
      },
      {
        title: "Overall leadership levels",
        icon: LeaderBoard,
        route: "summary/overall-leadership-level",
      },
      {
        title: "Practice improvement opportunities",
        icon: FieldMonitoring,
        route: "summary/practice-improvement-opportunities",
      },
    ],
  },
  {
    icon: ShowChart,
    tabName: "SAI indicators",
    key: "sai-indicators",
    content: [
      {
        title: "SAI performance",
        icon: Analytics,
        route: "sai-indicators/sai-performance",
      },
      {
        title: "Average scores",
        icon: Score,
        route: "sai-indicators/average-scores",
      },
      {
        title: "Farmers by % of questions",
        icon: Person,
        route: "sai-indicators/farmers",
      },
    ],
  },
  {
    tabName: "Outcomes",
    icon: BarChart,
    key: "outcome",
    content: [
      {
        title: "Biodiversity and habitat",
        icon: BiodiversityAndHabitat,
        route: "outcome/biodiversity-and-habitat-levels",
      },
      {
        title: "Community leadership",
        icon: Group,
        route: "outcome/community-leadership",
      },
      {
        title: "Human and animal health",
        icon: HealthAndSafety,
        route: "outcome/human-and-animal-health",
      },
      {
        title: "Optimal production",
        icon: OptimalProduction,
        route: "outcome/optimal-production",
      },
      {
        title: "Soil health",
        icon: Soil,
        route: "outcome/soil-health",
      },
      {
        title: "Water impact irrigated",
        icon: Water,
        route: "outcome/water-impact-irrigated",
      },
      {
        title: "Water impact rainfed",
        icon: Umbrella,
        route: "outcome/water-impact-rainfed",
      },
    ],
  },
  {
    tabName: "Management practices",
    icon: FieldMonitoring,
    key: "management-practices",
    content: [
      {
        title: "Crop productions",
        icon: Crop,
        route: "management-practices/crop-productions",
      },
      {
        title: "Nutrient management",
        icon: Seeds,
        route: "management-practices/nutrient-management",
      },
      {
        title: "Pest management",
        icon: PestControl,
        route: "management-practices/pest-management",
      },
      {
        title: "Soil health",
        icon: Soil,
        route: "management-practices/soil-health-1",
      },
      {
        title: "Soil health - 2",
        icon: Soil,
        route: "management-practices/soil-health-2",
      },
      {
        title: "Water quality",
        icon: Water,
        route: "management-practices/water-quality",
      },
      {
        title: "Biodiversity and habitat",
        icon: BiodiversityAndHabitat,
        route: "management-practices/biodiversity-and-habitat",
      },
    ],
  },
];
export const STATUS = {
  IN_PROGRESS: "In progress",
  SUBMITTED: "Submitted",
  NOT_STARTED: "Not started",
};
