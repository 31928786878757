import { type ChartOptions } from "chart.js";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { neutral } from "../../../../constants/colorsPallete";
import { InlineLoader } from "../../../../shared/components/Loader";
import StackedBarChart from "../../../../shared/components/StackedBarChart";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getOutcomeSummary } from "../../../../transformer/summary/outcome-overview";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { OutcomeDataSetContext } from "../../OutcomeDetails";
import { type OutcomeDataSet } from "../../types";

const OutcomeOverview = (): ReactElement => {
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);
  const [outcomeOverview, setOutcomeOverview] = useState<OutcomeDataSet>({
    datasets: [],
    labels: [],
  });

  const options: ChartOptions<"bar"> = {
    indexAxis: "x",
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
        title: {
          text: "Leadership levels",
          display: true,
          font: { size: 14 },
          padding: {
            top: 30,
          },
          color: neutral["70"],
        },
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 8,
          boxHeight: 8,
          color: neutral["100"],
        },
        reverse: true,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        formatter: (value) => (value !== 0 ? `${String(value)}%` : ""),
        color: (context) => {
          if (context?.dataset?.label === "High") {
            return "white";
          } else {
            return "black";
          }
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Outcome",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: neutral["50"],
          font: {
            size: 12,
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: false,
        max: 100,
        title: {
          display: true,
          text: "% of farmers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 20,
          callback: (value: string | number) => `${value}%`,
          color: neutral["50"],
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const eventName = `${APP_MODULES.SUMMARY}_${APP_MODULES.OUTCOME_OVERVIEW}`;
  useCallAmplitudeEvent(eventName);

  useEffect(() => {
    const outcomeOverviewDataSet = getOutcomeSummary(outcomeOverviewContext);
    setOutcomeOverview(outcomeOverviewDataSet);
  }, []);

  return (
    <div className="driver-table-main">
      <p className="title">Outcome overview</p>
      {outcomeOverviewContext ? (
        <StackedBarChart options={options} data={outcomeOverview} />
      ) : (
        <InlineLoader />
      )}
    </div>
  );
};
export default OutcomeOverview;
