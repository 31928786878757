import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import React, { type ReactElement } from "react";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { neutral } from "../../../../constants/colorsPallete";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { getFertilizerAverage } from "../../../../transformer/management-practices/fertilizer-average";
import { type GenericDataType } from "../types";

const FertilizerAverage = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "center",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "end" : "center",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? neutral["100"] : "black";
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? neutral["100"] : "black";
          }
          return "black";
        },
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Fertilizer",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Lbs per acre",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 40,
        },
      },
    },
  };

  const result = getFertilizerAverage(growersDetails);
  return <VerticalBarChart options={options} data={result} />;
};
export default FertilizerAverage;
