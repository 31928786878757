import _, { capitalize } from "lodash";
import { teal } from "../../constants/colorsPallete";
import {
  LOCALE_EN,
  WATER_IMPACT_OUTCOME_NUMBER,
  WATER_MANAGEMENT_DRIVER_NUMBER,
} from "../constants";
import { getTotalOutcomeScore } from "../summary/outcome-overview";

type numberMap = Record<string, any>;

export const getOutcomeDetails = (data: any): any => {
  const { userActivityScore, languages } = data;
  const driverNumberMap = data.driverNumberMap as numberMap;
  const outcomeNumberMap = data.outcomesNumberMap as numberMap;
  const result: any = {};
  const allLabels = ["Essential", "Basic", "Medium", "High"];
  const outcomeNameMap = getTotalOutcomeScore(data);
  const outcomeDriverMap = new Map();
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];

  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return item.outcomeNumber as string;
    })
    .forEach((value, key) => {
      const driverMap = new Map();

      value.forEach((item: any) => {
        let driverScore = driverMap.get(item.driverNumber);
        if (!driverScore) {
          driverScore = {
            essential: 0,
            basic: 0,
            medium: 0,
            high: 0,
          };
        }
        if (item.score === 0) {
          driverScore.essential++;
        } else if (item.score === 1) {
          driverScore.basic++;
        } else if (item.score === 2) {
          driverScore.medium++;
        } else {
          driverScore.high++;
        }
        driverMap.set(item.driverNumber, driverScore);
      });
      outcomeDriverMap.set(key, driverMap);
    })
    .value();
  Object.keys(outcomeNumberMap).forEach((outcome: string) => {
    const outcomeObj = outcomeNameMap.get(outcome);
    const driverObj = outcomeDriverMap.get(outcome);
    const labels: any = [];
    const score: any = [];
    allLabels.forEach((label) => {
      if (outcomeObj[label] !== 0) {
        labels.push(label);
        score.push(outcomeObj[label]);
      }
    });
    const drivers: any[] = [];
    driverObj.forEach((val: any, key: string) => {
      const driverName: string = capitalize(driverNumberMap[key][locale]);
      drivers.push({ [`${driverName}`]: val });
    });
    let number: string = outcome;
    if (number === WATER_IMPACT_OUTCOME_NUMBER) {
      number = number + " - irrigated";
    }
    result[number] = {
      labels,
      datasets: [
        {
          label: "Growers",
          data: score,
          backgroundColor: teal["50"],
        },
      ],
      drivers,
    };
  });
  const waterdriverObj = outcomeDriverMap.get(WATER_IMPACT_OUTCOME_NUMBER);
  const waterManagement = waterdriverObj.has(WATER_MANAGEMENT_DRIVER_NUMBER)
    ? waterdriverObj.get(WATER_MANAGEMENT_DRIVER_NUMBER)
    : [];
  const waterLabels: any = [];
  const waterScore: any = [];
  allLabels.forEach((label) => {
    if (waterManagement[label.toLowerCase()] !== 0) {
      waterLabels.push(label);
      waterScore.push(waterManagement[label.toLowerCase()]);
    }
  });
  const dname: string = capitalize(
    driverNumberMap[WATER_MANAGEMENT_DRIVER_NUMBER][locale]
  );
  result[WATER_IMPACT_OUTCOME_NUMBER + " - rainfed"] = {
    labels: waterLabels,
    datasets: [
      {
        label: "Growers",
        data: waterScore,
        backgroundColor: teal["50"],
      },
    ],
    drivers: [{ [`${dname}`]: waterManagement }],
  };
  return result;
};
