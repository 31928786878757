import React, { createContext, type ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAxios from "../../../shared/custom-hooks/useAxios";
import { useCallAmplitudeEvent } from "../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getPracticeCount } from "../../../transformer/management-practices/nutrient-management";
import { APP_MODULES } from "../../amplitude-event-constants";

export const ManagementPracticeDataSetContext = createContext<
  Record<string, any>
>({});
export const ManagementPractice = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const { loading: answerApiLoading, response: answerApiResponse } = useAxios(
    `/answer/practice/${String(partnerId)}/${String(cropYear)}`
  );

  const {
    loading: cropPerformanceApiLoading,
    response: cropPerformanceApiResponse,
  } = useAxios(`/crop-performance/${String(partnerId)}/${String(cropYear)}`);

  const answerApiTransformedData = answerApiLoading
    ? {}
    : getPracticeCount(answerApiResponse);

  useCallAmplitudeEvent(APP_MODULES.MANAGEMENT_PRACTICE);
  return (
    <ManagementPracticeDataSetContext.Provider
      value={{
        cropPerformanceApiResponse,
        answerApiResponse,
        answerApiLoading,
        cropPerformanceApiLoading,
        answerApiTransformedData,
      }}
    >
      <Outlet />
    </ManagementPracticeDataSetContext.Provider>
  );
};
