import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { blue, neutral } from "../../../../constants/colorsPallete";
import PieChart from "../../../../shared/components/PieChart";
import { calculateAverage } from "../utils";

const AverageCroppedAndNonCroppedAcres = ({
  totalAcres,
  totalAcresOfAllCrops,
}: {
  totalAcres: number;
  totalAcresOfAllCrops: number;
}): ReactElement => {
  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    animation: false,
    plugins: {
      datalabels: {
        formatter: (value) => `${String(value)}%`,
        color: "white",
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          color: neutral["100"],
          font: {
            size: 16,
          },
          boxHeight: 8,
          boxWidth: 8,
        },
      },
    },
  };
  const totalArea =
    parseInt(`${totalAcres}`) + parseInt(`${totalAcresOfAllCrops}`);
  const averageCropped = calculateAverage(totalAcres, totalArea);
  const averageNonCropped = calculateAverage(totalAcresOfAllCrops, totalArea);

  const data = {
    labels: ["Primary crop", "Other crops"],
    datasets: [
      {
        data: [averageCropped, averageNonCropped],
        backgroundColor: [blue["70"], blue["60"]],
        borderWidth: 0,
      },
    ],
  };
  return <PieChart options={options} data={data} />;
};
export default AverageCroppedAndNonCroppedAcres;
