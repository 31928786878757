/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { Suspense, useState } from "react";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import "./index.less";
import { AppRoutes } from "./pages";
import { ErrorBoundary } from "./shared/components/ErrorBoundary";
import { Loader } from "./shared/components/Loader";
import "./shared/components/style.less";
import { GlobalHeader } from "./shared/layout/GlobalHeader";
import { SidebarMenu } from "./shared/layout/Sidebar";
import { ThemeProvider } from "./shared/layout/ThemeProvider";
const { Content } = Layout;

export default function App(): JSX.Element | null {
  const [triggerClear, setTriggerClear] = useState(false);
  const handleMenuClick = (): void => {
    setTriggerClear(true);
  };

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <div className="app-root">
          <GlobalHeader />
          <Layout className="cw-page-container">
            <Suspense fallback={<Loader mask={true} message="Loading..." />}>
              <SidebarMenu onMenuItemClick={handleMenuClick} />
              <Layout className="main-layout">
                <Content className="main-content">
                  <AppRoutes
                    triggerClear={triggerClear}
                    setTriggerClear={setTriggerClear}
                  ></AppRoutes>
                </Content>
              </Layout>
            </Suspense>
          </Layout>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
