import React, { type ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  type ChartData,
  type ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface HorizontalBarChartProps {
  data: ChartData<"bar">;
  options?: ChartOptions<"bar">;
  width?: number;
  height?: number;
  redraw?: boolean;
}

const HorizontalBarChart = ({
  data,
  options = {},
  width = 400,
  height = 200,
  ...props
}: HorizontalBarChartProps): ReactElement => {
  options = {
    ...options,
    indexAxis: "y",
    responsive: options?.responsive ?? true,
  };

  options.scales = {
    ...options.scales,
    x: {
      ...options.scales?.x,
      title: {
        display: true,
        ...options.scales?.x?.title,
      },
    },
    y: {
      ...options.scales?.y,
      title: {
        display: true,
        ...options.scales?.y?.title,
      },
    },
  };

  // by default alignment of chart title is center, as per our chart component usage, keeping alignment default as left
  options.plugins = {
    ...options?.plugins,
    legend: {
      display: false, // default legend to false as most of the chart don't legends
      ...options?.plugins?.legend,
    },
    title: {
      align: "start",
      ...options?.plugins?.title,
      display:
        options?.plugins?.title?.display ?? !!options?.plugins?.title?.text,
    },
  };

  return (
    <Bar
      data-testid="chart-canvas-horizontal"
      data={data}
      options={options}
      width={width}
      height={height}
      {...props}
    />
  );
};

export default HorizontalBarChart;
