import React, { createContext, type ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import { InlineLoader } from "../../shared/components/Loader";
import useAxios from "../../shared/custom-hooks/useAxios";
import { type OutcomeDataSet } from "./types";

export const OutcomeDataSetContext = createContext<OutcomeDataSet>({
  datasets: [],
  labels: [],
});

export const OutcomesDetails = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const { loading, response } = useAxios(
    `/outcome/details/${String(partnerId)}/${String(cropYear)}`
  );

  if (loading) {
    return <InlineLoader top="20vh" position="relative" />;
  }

  return (
    <OutcomeDataSetContext.Provider value={response}>
      <Outlet />
    </OutcomeDataSetContext.Provider>
  );
};
