import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { neutral, purple } from "../../../../constants/colorsPallete";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { type GenericDataType } from "../types";
import { getChartData } from "../utils";

const LivestockGraze = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "top" : "start",
        formatter: (value) => (value >= 1 ? value : 0),
        color: neutral["100"],
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Livestock grazing methods",
          color: neutral["70"],
          font: {
            size: 16,
          },
          padding: 0,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
          color: neutral["50"],
        },
      },
    },
  };
  const { data, labels } = growersDetails;
  const chartData = getChartData(labels, data, purple["40"], "Growers");

  return <VerticalBarChart options={options} data={chartData} height={200} />;
};
export default LivestockGraze;
