import React, {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactElement,
} from "react";
import { Outlet, useRoutes } from "react-router-dom";
import { Reports } from "./reports";
import ReportsNavigationAndOverview from "./reports-overview-and-navigation";
import { type ReportDetails } from "./reports-overview-and-navigation/types";
import { ManagementPractice } from "./reports/management-practices";
import BiodiversityandHabitat from "./reports/management-practices/biodiversity-and-habitat";
import CropProductions from "./reports/management-practices/crop-productions";
import NutrientManagement from "./reports/management-practices/nutrient-management";
import PestManagement from "./reports/management-practices/pest-management";
import SoilHealthPractices from "./reports/management-practices/soil-health";
import SoilHealthLivestock from "./reports/management-practices/soil-health-2";
import WaterQuality from "./reports/management-practices/water-quality";
import { Outcomes } from "./reports/outcomes";
import BiodiversityAndHabitatLevels from "./reports/outcomes/biodiversity-and-habitat-levels";
import CommunityLeadership from "./reports/outcomes/community-leadership";
import HumanAndAnimalHealth from "./reports/outcomes/human-and-animal-health";
import OptimalProduction from "./reports/outcomes/optimal-production";
import SoilHealth from "./reports/outcomes/soil-health";
import WaterImpactIrrigated from "./reports/outcomes/water-impact-irrigated";
import WaterImpactRainfed from "./reports/outcomes/water-impact-rainfed";
import { Overview } from "./reports/overview";
import AcresByCity from "./reports/overview/acres-by-city";
import Context from "./reports/overview/context";
import { SaiIndicator } from "./reports/sai-indicator";
import Farmers from "./reports/sai-indicator/Farmers";
import AverageScores from "./reports/sai-indicator/average-scores";
import SaiPerformance from "./reports/sai-indicator/sai-performance";
import { Summary } from "./reports/summary";
import DriversRankedByScore from "./reports/summary/driver-ranked-by-score";
import OpportunitiesToImprovePerformance from "./reports/summary/opportunities-to-improve-performance";
import OutcomeOverview from "./reports/summary/outcome-overview";
import OverAllLeaderShipLevel from "./reports/summary/overall-leadership-level";
import PracticeImprovementOpportunities from "./reports/summary/practice-improvement-opportunities";

interface PartnerDetailsContextProps {
  partner: string;
  reportsByPartner: ReportDetails[];
  report: string;
  setPartner: (partner: string) => void;
  setReportsByPartner: (reports: ReportDetails[]) => void;
  setReport: (report: string) => void;
  clearPartnerDetails: () => void;
}
// Provide a structured default value for the context
const defaultContextValue: PartnerDetailsContextProps = {
  partner: "",
  reportsByPartner: [],
  report: "",
  setPartner: () => {},
  setReportsByPartner: () => {},
  setReport: () => {},
  clearPartnerDetails: () => {},
};

export const PartnerDetailsContext =
  createContext<PartnerDetailsContextProps>(defaultContextValue);
interface AppRoutesProps {
  triggerClear: boolean;
  setTriggerClear?: any;
}

export const AppRoutes: FC<PropsWithChildren<AppRoutesProps>> = ({
  triggerClear,
  setTriggerClear,
}): ReactElement | null => {
  const [partner, setPartner] = useState<string>("");
  const [report, setReport] = useState<string>("");
  const [reportsByPartner, setReportsByPartner] = useState<ReportDetails[]>([]);

  const getPartnersScreen = (): ReactElement => {
    return (
      <PartnerDetailsContext.Provider
        value={{
          partner,
          reportsByPartner,
          report,
          setPartner,
          setReportsByPartner,
          setReport,
          clearPartnerDetails,
        }}
      >
        <Outlet />
        {triggerClear && (
          <ClearPartnerDataEffect setTriggerClear={setTriggerClear} />
        )}
      </PartnerDetailsContext.Provider>
    );
  };
  const clearPartnerDetails = (): void => {
    setReport("");
    setPartner("");
    setReportsByPartner([]);
  };
  return useRoutes([
    {
      path: "/app",
      element: getPartnersScreen(),
      children: [
        {
          path: "partners",
          element: <ReportsNavigationAndOverview />,
        },
        {
          path: "partners/:partnerId/:cropYear",
          element: <Reports />,
          children: [
            {
              path: "summary",
              element: <Summary />,
              children: [
                {
                  path: "outcome-overview",
                  element: <OutcomeOverview />,
                },
                {
                  path: "drivers-ranked-by-score",
                  element: <DriversRankedByScore />,
                },
                {
                  path: "opportunities-to-improve-performance",
                  element: <OpportunitiesToImprovePerformance />,
                },
                {
                  path: "overall-leadership-level",
                  element: <OverAllLeaderShipLevel />,
                },
                {
                  path: "practice-improvement-opportunities",
                  element: <PracticeImprovementOpportunities />,
                },
              ],
            },
            {
              path: "overview",
              element: <Overview />,
              children: [
                {
                  path: "context",
                  element: <Context />,
                },
                {
                  path: "acres-by-city",
                  element: <AcresByCity />,
                },
              ],
            },
            {
              path: "outcome",
              element: <Outcomes />,
              children: [
                {
                  path: "biodiversity-and-habitat-levels",
                  element: <BiodiversityAndHabitatLevels />,
                },
                {
                  path: "community-leadership",
                  element: <CommunityLeadership />,
                },
                {
                  path: "human-and-animal-health",
                  element: <HumanAndAnimalHealth />,
                },
                {
                  path: "optimal-production",
                  element: <OptimalProduction />,
                },
                {
                  path: "water-impact-irrigated",
                  element: <WaterImpactIrrigated />,
                },
                {
                  path: "water-impact-rainfed",
                  element: <WaterImpactRainfed />,
                },
                {
                  path: "soil-health",
                  element: <SoilHealth />,
                },
              ],
            },
            {
              path: "sai-indicators",
              element: <SaiIndicator />,
              children: [
                {
                  path: "average-scores",
                  element: <AverageScores />,
                },
                {
                  path: "sai-performance",
                  element: <SaiPerformance />,
                },
                {
                  path: "farmers",
                  element: <Farmers />,
                },
              ],
            },
            {
              path: "management-practices",
              element: <ManagementPractice />,
              children: [
                {
                  path: "crop-productions",
                  element: <CropProductions />,
                },
                {
                  path: "nutrient-management",
                  element: <NutrientManagement />,
                },
                {
                  path: "pest-management",
                  element: <PestManagement />,
                },
                {
                  path: "water-quality",
                  element: <WaterQuality />,
                },
                {
                  path: "biodiversity-and-habitat",
                  element: <BiodiversityandHabitat />,
                },
                {
                  path: "soil-health-1",
                  element: <SoilHealthPractices />,
                },
                {
                  path: "soil-health-2",
                  element: <SoilHealthLivestock />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};

interface ClearPartnerDataEffectProps {
  setTriggerClear: (value: boolean) => void;
}
const ClearPartnerDataEffect = (
  props: ClearPartnerDataEffectProps
): ReactElement | null => {
  const { setTriggerClear } = props;
  const { clearPartnerDetails } = useContext(PartnerDetailsContext);
  useEffect(() => {
    clearPartnerDetails();
    setTriggerClear(false);
  }, [setTriggerClear]);
  return null;
};
