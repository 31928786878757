import {
  CloseCircleOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Table as AntdTable, Input } from "antd";
import React, { useEffect, useState, type ReactElement } from "react";
import { Typography } from "syngenta-digital-cropwise-react-ui-kit";
import "./style.less";
import { type TableProps } from "./types";
type DataSource = Record<string, string | any>;

export const Table = <T extends object>({
  columns,
  dataSource,
  pagination,
  bordered,
  outerBorderOnly,
  searchBox,
  tableTitle,
  ...props
}: TableProps<T>): ReactElement => {
  const [filteredColumns, setFilteredColumns] = useState<T[]>([
    ...(dataSource ?? []),
  ]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (searchBox && searchText && dataSource?.length) {
      setFilteredColumns(
        dataSource.filter((col: DataSource) =>
          Object.keys(col).some((columnKey: string) =>
            col[columnKey]
              ?.toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
        )
      );
    } else {
      setFilteredColumns([...(dataSource ?? [])]);
    }
  }, [columns, searchBox, searchText, dataSource]);

  const clearText = (): void => {
    setSearchText("");
  };

  return (
    <>
      {searchBox && (
        <Input
          className="search-input"
          value={searchText}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          suffix={
            <div className="suffix-icon">
              <CloseCircleOutlined
                data-testid="clear-search"
                onClick={clearText}
              />
              <DownOutlined />
            </div>
          }
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder={"Search by Unique ID, Crop or Status."}
        />
      )}
      {tableTitle && (
        <Typography.Text className="left-navigator__item-text">
          {tableTitle}
        </Typography.Text>
      )}
      <AntdTable
        {...props}
        columns={columns}
        dataSource={filteredColumns}
        className={
          outerBorderOnly
            ? "table-outer-border-only table-main"
            : "table-bordered"
        }
        bordered={bordered}
        pagination={{
          ...pagination,
          hideOnSinglePage: true,
          defaultPageSize: 10,
          pageSizeOptions: pagination?.pageSizeOptions ?? [
            "5",
            "10",
            "30",
            "50",
            "100",
          ],
          showSizeChanger: pagination?.showSizeChanger ?? false,
        }}
      />
    </>
  );
};

export default Table;
