import { type ChartOptions } from "chart.js";
import React, { useContext, type ReactElement } from "react";
import { SaiIndicatorDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { InlineLoader } from "../../../../shared/components/Loader";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { getFarmersEssentialQuestions } from "../../../../transformer/sai-indicator/farmers-essential-fsa-questions";

const FarmersFsaQuestion = (): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "bottom",
        rotation: 270,
        color: "White",
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Essential FSA questions",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: neutral["50"],
          minRotation: 90,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          text: "Farmers %",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 20,
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
      },
    },
  };
  const { saiIndicatorApiResponse, loading } = useContext(
    SaiIndicatorDataSetContext
  );
  const farmersFSAQuestionsDataSet = loading
    ? {}
    : getFarmersEssentialQuestions(saiIndicatorApiResponse);
  if (loading) {
    return (
      <InlineLoader text="Loading graph..." top="10vh" position="relative" />
    );
  }

  return (
    <VerticalBarChart options={options} data={farmersFSAQuestionsDataSet} />
  );
};

export default FarmersFsaQuestion;
