export const blue = {
  10: "rgba(234, 246, 255, 1)",
  20: "rgba(198, 230, 255, 1)",
  30: "rgba(130, 207, 255, 1)",
  40: "rgba(49, 180, 242, 1)",
  50: "rgba(0, 146, 228, 1)",
  60: "rgba(0, 113, 205, 1)",
  70: "rgba(0, 86, 147, 1)",
  80: "rgba(0, 59, 105, 1)",
  90: "rgba(0, 39, 71, 1)",
  100: "rgba(0, 23, 45, 1)",
  brandColor: "rgba(0, 0, 75, 1)",
};
export const teal: any = {
  10: "rgba(224,249,247,1)",
  20: "rgba(169, 239, 232, 1)",
  30: "rgba(95, 210, 200, 1)",
  40: "rgba(70, 187, 176, 1)",
  50: "rgba(43, 156, 146, 1)",
  60: "rgba(33, 124, 116, 1)",
  70: "rgba(22, 92, 86, 1)",
  80: "rgba(13, 65, 60, 1)",
  90: "rgba(6, 43, 39, 1)",
  100: "rgba(2, 25, 23, 1)",
  brandColor: "rgba(0, 0, 75, 1)",
};

export const neutral = {
  10: "rgba(243, 244, 246, 1)",
  20: "rgba(223, 226, 231, 1)",
  30: "rgba(194, 199, 208, 1)",
  40: "rgba(163, 169, 185, 1)",
  50: "rgba(134, 140, 162, 1)",
  60: "rgba(105, 111, 136, 1)",
  70: "rgba(77, 81, 101, 1)",
  80: "rgba(54, 57, 72, 1)",
  90: "rgba(35, 38, 48, 1)",
  100: "rgba(20, 21, 28, 1)",
};

export const purple = {
  10: "rgba(246, 242, 254, 1)",
  20: "rgba(231, 221, 252, 1)",
  30: "rgba(210, 189, 249, 1)",
  40: "rgba(186, 153, 246, 1)",
  50: "rgba(150, 100, 240, 1)",
  60: "rgba(131, 84, 214, 1)",
  70: "rgba(100, 58, 170, 1)",
  80: "rgba(70, 39, 121, 1)",
  90: "rgba(46, 25, 84, 1)",
  100: "rgba(28, 13, 53, 1)",
};

export const green = {
  10: "rgba(223, 251, 232, 1)",
  20: "rgba(195, 234, 209, 1)",
  30: "rgba(144, 214, 170, 1)",
  40: "rgba(94, 187, 127, 1)",
  50: "rgba(25, 160, 75, 1)",
  60: "rgba(20, 128, 60, 1)",
  70: "rgba(12, 97, 44, 1)",
  80: "rgba(6, 67, 28, 1)",
  90: "rgba(3, 45, 16, 1)",
  100: "rgba(1, 27, 7, 1)",
};

export const red = {
  10: "rgba(255, 241, 241, 1)",
  20: "rgba(255, 217, 217, 1)",
  30: "rgba(255, 179, 179, 1)",
  40: "rgba(249, 137, 137, 1)",
  50: "rgba(235, 75, 75, 1)",
  60: "rgba(207, 53, 55, 1)",
  70: "rgba(156, 38, 40, 1)",
  80: "rgba(112, 25, 26, 1)",
  90: "rgba(76, 14, 15, 1)",
  100: "rgba(48, 6, 6, 1)",
};

export const orange = {
  10: "rgba(255, 241, 237, 1)",
  20: "rgba(255,218, 206, 1)",
  30: "rgba(255, 181, 157, 1)",
  40: "rgba(255, 120, 90, 1)",
  50: "rgba(238, 91, 58, 1)",
  60: "rgba(193, 70, 43, 1)",
  70: "rgba(146, 51, 30, 1)",
  80: "rgba(104, 34, 19, 1)",
  90: "rgba(72, 21, 10, 1)",
  100: "rgba(45, 10, 4, 1)",
};

export const yellow = {
  10: "rgba(255, 243, 221, 1)",
  20: "rgba(255, 228, 174, 1)",
  30: "rgba(240, 195, 85, 1)",
  40: "rgba(233, 153, 33, 1)",
  50: "rgba(193, 126, 25, 1)",
  60: "rgba(154, 100, 18, 1)",
  70: "rgba(116, 74, 11, 1)",
  80: "rgba(82, 51, 5, 1)",
  90: "rgba(55, 33, 2, 1)",
  100: "rgba(34, 18, 1, 1)",
};

export const pink = {
  10: "rgba(255, 241, 243, 1)",
  20: "rgba(255, 216, 223, 1)",
  30: "rgba(255, 178, 193, 1)",
  40: "rgba(255, 125, 150, 1)",
  50: "rgba(233, 93,120, 1)",
  60: "rgba(187, 72, 93, 1)",
  70: "rgba(141, 53, 69, 1)",
  80: "rgba(101, 35, 47, 1)",
  90: "rgba(69, 22, 30, 1)",
  100: "rgba(43, 10, 16, 1)",
};
