import { type ChartOptions } from "chart.js";
import React, { type ReactElement } from "react";
import { neutral, purple } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { type GenericDataType } from "../types";
import { getChartData } from "../utils";

const TillagePercentage = ({
  growersDetails,
}: {
  growersDetails: GenericDataType;
}): ReactElement => {
  const { data, labels } = growersDetails;
  const chartData = getChartData(labels, data, purple["50"], "Tillage");

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "top" : "start",
        formatter: (value) => (value >= 1 ? value : 0),
        color: "white",
      },
      tooltip: {
        callbacks: {
          title: (context) => labels[context[0]?.dataIndex || 0],
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Tillage %",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 15,
          color: neutral["50"],
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (value, index) => chartData.labels[index],
          color: neutral["50"],
          padding: 0,
        },
      },
    },
  };

  return <HorizontalBarChart options={options} data={chartData} height={180} />;
};

export default TillagePercentage;
