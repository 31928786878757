import { Col, Row } from "antd";
import { type ChartOptions } from "chart.js";
import startCase from "lodash/startCase";
import { type ReactElement } from "react";
import { neutral } from "../../../constants/colorsPallete";
import HorizontalBarChart from "../../../shared/components/HorizontalBarChart";
import VerticalBarChart from "../../../shared/components/VerticalBarChart";
import "../../style.less";
import { type HabitatQualityProps } from "./types";
import { backgroundGenerate, generateOutcomesDriversSequence } from "./utils";

const OutcomesDrivers = ({
  driverDetails,
  horizontalChart = false,
}: HabitatQualityProps & { horizontalChart?: boolean }): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        formatter: (value) => (value !== 0 ? value : ""),
        color: (context) => {
          const backgroundColor = context?.dataset?.backgroundColor as string;
          if (backgroundColor?.includes("rgba(70, 187, 176, 1)")) {
            return "white";
          } else {
            return "black";
          }
        },
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Levels",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  const driverNames = Object.keys(driverDetails);

  const outcomeDriverSequence = generateOutcomesDriversSequence(
    driverNames.length
  );

  const expandedSpanSequence = outcomeDriverSequence.flatMap((value) =>
    Array(value).fill(value)
  );
  const background = backgroundGenerate(outcomeDriverSequence);

  const driverDatasets = driverNames.map((driverName, index) => {
    const backgroundColor = background[index];
    const labels: string[] = [];
    const data: number[] = [];
    Object.entries(driverDetails[driverName]).forEach(([key, value]) => {
      if (value > 0) {
        const pascalCaseKey = startCase(key).toString();
        labels?.push(pascalCaseKey);
        data?.push(value);
      }
    });

    return {
      labels,
      label: driverName,
      data,
      backgroundColor,
    };
  });

  return (
    <Row gutter={12} className="driver">
      {driverNames.map((driverName, index) => {
        const colSpan = 24 / expandedSpanSequence[index];
        const isVerticalChart = index < outcomeDriverSequence[0];

        const axisOptions = isVerticalChart
          ? options.scales
          : {
              x: options?.scales?.y,
              y: options?.scales?.x,
            };
        return (
          <Col className="driver-chart-main" span={colSpan} key={driverName}>
            <div className="outcomes-growes-chart">
              <p className="title">{`${driverName}`}</p>
              {horizontalChart ? (
                index < outcomeDriverSequence[0] ? (
                  <VerticalBarChart
                    options={{
                      ...options,
                      scales: axisOptions,
                    }}
                    data={{
                      labels: driverDatasets[index].labels,
                      datasets: [driverDatasets[index]],
                    }}
                  />
                ) : (
                  <HorizontalBarChart
                    options={{
                      ...options,
                      scales: axisOptions,
                    }}
                    data={{
                      labels: driverDatasets[index].labels,
                      datasets: [driverDatasets[index]],
                    }}
                  />
                )
              ) : (
                <VerticalBarChart
                  options={options}
                  data={{
                    labels: driverDatasets[index].labels,
                    datasets: [driverDatasets[index]],
                  }}
                  height={170}
                />
              )}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default OutcomesDrivers;
