import _ from "lodash";
import { green } from "../../constants/colorsPallete";
import { LEADERSHIP_LEVELS, LEADERSHIP_USER_PERFORMANCE, LOCALE_EN } from "../constants";
import { type UserActivityScore } from "../types";
import { lablesSet } from "../../pages/reports/management-practices/utils";


export const color: Record<string, string> = {
  High: green["50"],
  Medium: green["40"],
  Basic: green["30"],
  Essential: green["20"],
};
export const getOutcomeSummary = (data: any): any => {
  const { totalFarmers, outcomesNumberMap, languages } = data;
  const outcomeSort = Object.keys(outcomesNumberMap).sort(([key1],[key2])=>parseInt(key1)-parseInt(key2));
  const outcomeNameMap = getTotalOutcomeScore(data);
  const level = LEADERSHIP_LEVELS;
  const datasets = new Array(level.length);

  level.forEach((_value, index) => {
    datasets[index] = {
      backgroundColor: color[level[index]],
      data: new Array(outcomeSort.length).fill(0),
      label: level[index],
    };
  });
  const labelsArr:any = [];
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];
  outcomeSort.forEach((number:string,index:number) => {
    const outcomeScore = outcomeNameMap.get(number);
    labelsArr.push(outcomesNumberMap[number][locale]);
    level.forEach((value, index1) => {
        datasets[index1].data[index] = parseFloat(
        ((outcomeScore[value] * 100) / totalFarmers).toFixed(2)
        );
    });
  });
  const modifiedLables = lablesSet(labelsArr);
  return {
    labels: modifiedLables,
    datasets,
  };
};

export const getTotalOutcomeScore = (data: any): any => {
  const { userActivityScore, outcomesNumberMap } = data;
  const outcomeNameMap = new Map();
  Object.keys(outcomesNumberMap).forEach((outcome: string) => {
    outcomeNameMap.set(outcome, { High: 0, Medium: 0, Basic: 0, Essential: 0 });
  });
  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return `${item.planId as string}::${item.outcomeNumber as string}`;
    })
    .forEach((value, key) => {
      const score = value.reduce(
        (acc: number, val: UserActivityScore) => acc + val.score,
        0
      );
      const avgScore = parseFloat((score / value.length).toFixed(2));
      let userPerformanceLevel = null;
      const { ESSENTIAL, BASIC, MEDIUM, HIGH } = LEADERSHIP_USER_PERFORMANCE;
      if (avgScore < ESSENTIAL.upperLimit) {
        userPerformanceLevel = ESSENTIAL.title;
      } else if (avgScore >= BASIC.lowerLimit && avgScore < BASIC.upperLimit) {
        userPerformanceLevel = BASIC.title;
      } else if (
        avgScore >= MEDIUM.lowerLimit &&
        avgScore < MEDIUM.upperLimit
      ) {
        userPerformanceLevel = MEDIUM.title;
      } else if (avgScore >= HIGH.lowerLimit) {
        userPerformanceLevel = HIGH.title;
      }
      const outcomeName = key.split("::")[1];
      const outcomeScoreObj = outcomeNameMap.get(outcomeName);
      if (userPerformanceLevel) {
        outcomeScoreObj[userPerformanceLevel]++;
        outcomeNameMap.set(outcomeName, outcomeScoreObj);
      }
    })
    .value();
  return outcomeNameMap;
};
