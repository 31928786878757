import _ from "lodash";
import { getStatus } from "../../pages/reports/summary/utils";
import { LOCALE_EN } from "../constants";

export const getDriversRankedByScore = (data: any): any => {
  const { userActivityScore, totalFarmers, languages, outcomesNumberMap, driverNumberMap } = data;
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];
  const result: any = [];
  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return `${item.outcomeNumber as string}::${
        item.driverNumber as string
      }`;
    })
    .forEach((value, key) => {
      let sum: number = 0;
      value.forEach((driver) => {
        sum += driver?.score ? (driver.score as number) : 0;
      });
      const avgScore = parseFloat((sum / totalFarmers).toFixed(2));
      const keyName = key.split("::");

      result.push({
        driver: `${outcomesNumberMap[keyName[0]][locale] as string} - ${driverNumberMap[keyName[1]][locale] as string}`,
        key: keyName[1],
        score: avgScore,
        level: getStatus(avgScore),
      });
    })
    .value();
  return result;
};

export const getOutcomeScore = (data: any): any => {
  const { userActivityScore, totalFarmers, outcomesNumberMap, languages } = data;
  const result: any = [];
  const outcomeNameMap = new Map();

  const outcomeSort = Object.keys(outcomesNumberMap).sort(([key1],[key2])=>parseInt(key1)-parseInt(key2));
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];
  outcomeSort.forEach((number: string) => {
    outcomeNameMap.set(number,"")
  });
  let totalSum = 0;
  let totalDrivers = 0;
  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return item.outcomeNumber;
    })
    .forEach((value, key) => {
      const driverSet = new Set();
      let sum = 0;
      value.forEach((activityScore) => {
        driverSet.add(activityScore.driverNumber);
        sum += activityScore.score as number;
      });
      totalSum += sum;
      totalDrivers += driverSet.size;
      const avgScore = parseFloat(
        (sum / (totalFarmers * driverSet.size)).toFixed(2)
      );
      outcomeNameMap.set(key, getStatus(avgScore));
    })
    .value();
  const overallScore = getStatus(totalSum / (totalFarmers * totalDrivers));
  result.push({
    outcomeName: "Overall",
    status: overallScore,
  });
  outcomeNameMap.forEach((val, key) => {
    result.push({
      outcomeName: outcomesNumberMap[key][locale],
      status: val,
    });
  });
  return result;
};
