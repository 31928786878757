import { IRRIGATION_TYPES } from "./constants";

export const getFarmDetailsOverview = (data: any): any => {
  const { plans } = data;
  const result: any = [];
  plans.forEach((plan: any) => {
    let primaryCropArea = 0;
    let irrigatedType: string = "";
    let totalAreaNonCropped = 0;
    plan.planCropMap?.forEach((crop: any) => {
      if (crop.primary) {
        primaryCropArea = parseFloat(crop.acres);
        irrigatedType = crop.irrigation;
      } else {
        totalAreaNonCropped += parseFloat(parseFloat(crop.acres).toFixed(2));
      }
    });
    result.push({
      planId: plan.planId,
      totalArea: primaryCropArea,
      irrigatedArea:
        irrigatedType === IRRIGATION_TYPES.irrigated ? primaryCropArea : 0,
      rainfedArea:
        irrigatedType === IRRIGATION_TYPES.rainfed ? primaryCropArea : 0,
      cropped: primaryCropArea,
      "non-cropped": totalAreaNonCropped,
    });
  });
  return result;
};
