import { type ReactElement } from "react";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import CommonOutcomesModule from "../CommonOutcomesModule";

const CommunityLeadership = (): ReactElement => {
  const eventName = `${APP_MODULES.OUTCOMES}_${APP_MODULES.COMMUNITY_LEADERSHIP}`;
  useCallAmplitudeEvent(eventName);

  return (
    <CommonOutcomesModule
      outcomeKey="2.0.0"
      outcomeName="Community leadership levels"
    />
  );
};

export default CommunityLeadership;
