import {
  LOCALE_EN,
  NITROGEN_FERTILIZERS,
  NITROGEN_QUESTIONS,
} from "../constants";

export const getFertilizerAverage = (data: any): any => {
  const { answers, totalFarmers, languages } = data;
  const fertilizerAnswers = answers.filter((ans: any) =>
    NITROGEN_QUESTIONS.includes(ans.questionNumber)
  );
  const map = new Map();
  const languageObj: any = {};
  const labelIdMap: any = {};
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];

  languages.forEach((l: string) => {
    languageObj[l] = {};
  });
  NITROGEN_QUESTIONS.forEach((key: any) => {
    labelIdMap[key] = JSON.parse(JSON.stringify(languageObj));
  });
  fertilizerAnswers.forEach((answer: any) => {
    answer?.label.forEach((label: any) => {
      const key1: string = `${answer.questionNumber as string}::${
        label.labelId as string
      }`;
      if (NITROGEN_FERTILIZERS.includes(key1)) {
        labelIdMap[answer.questionNumber][answer.language][label.labelId] =
          label.labelName;
        const ans = label.answer ? label.answer : 0;
        if (map.has(key1)) {
          const sum = parseFloat(map.get(key1)) + parseFloat(ans);
          map.set(key1, sum);
        } else {
          map.set(key1, ans);
        }
      }
    });
  });
  const labels: any = [];
  const dataset: any = [];
  map.forEach((val, key) => {
    const keyNums = key.split("::");
    labels.push(labelIdMap[keyNums[0]][locale][keyNums[1]]);
    dataset.push((val / totalFarmers).toFixed(2));
  });
  return {
    labels,
    datasets: [
      {
        backgroundColor: "rgba(186, 153, 246, 1)",
        data: dataset,
        label: "Fertilizer",
      },
    ],
  };
};
