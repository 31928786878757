import type { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState, type ReactElement } from "react";
import { useParams } from "react-router";
import Table from "../../../../shared/components/Table";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import AxiosInstance from "../../../../shared/utils/axios";
import { getOutcomeScore } from "../../../../transformer/summary/driver-ranked-by-score";
import { getOpportunityToImprove } from "../../../../transformer/summary/opportunities-to-improve-performance";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { OutcomeDataSetContext } from "../../OutcomeDetails";
import OutcomeStatuses from "../OutcomeStatuses";
import { type OpportunitiesColumnDataType } from "../types";

const OpportunitiesToImprovePerformance = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);
  const [opportunitiesStatus, setOpportunitiesStatus] = useState<
    Array<{ outcomeName: string; status: string }>
  >([]);
  const [opportunitiesToImproveTableData, setOpportunitiesToImproveTableData] =
    useState<OpportunitiesColumnDataType[]>([]);
  const columns: ColumnsType<OpportunitiesColumnDataType> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Outcome - Driver</div>,
      dataIndex: "outcomeDriver",
      key: "outcomeDriver",
      sorter: (a, b) => a.outcomeDriver.localeCompare(b.outcomeDriver),
      sortDirections: ["descend", "ascend"],
      width: "42%",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Current level</div>,
      dataIndex: "currentLevel",
      key: "currentLevel",
      sorter: (a, b) => a.currentLevel.localeCompare(b.currentLevel),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>Opportunity for improvement</div>
      ),
      dataIndex: "opportunityForImprovement",
      key: "opportunityForImprovement",
      sorter: (a, b) =>
        a.opportunityForImprovement.localeCompare(b.opportunityForImprovement),
      sortDirections: ["descend", "ascend"],
      width: "42%",
    },
  ];

  const eventName = `${APP_MODULES.SUMMARY}_${APP_MODULES.OPPORTUNITIES_TO_IMPROVE_PERFORMANCE}`;
  useCallAmplitudeEvent(eventName);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const outcomeScore = await getOutcomeScore(outcomeOverviewContext);
      setOpportunitiesStatus(outcomeScore);
      await AxiosInstance.get(
        `/outcome/summary/opportunity/${String(partnerId)}/${String(cropYear)}`
      )
        .then((opportunitiesToImprovePerformance) => {
          const opportunitiesToImrovePerformanceTableData =
            getOpportunityToImprove(
              outcomeOverviewContext,
              opportunitiesToImprovePerformance.data.opportunity
            );
          setOpportunitiesToImproveTableData(
            opportunitiesToImrovePerformanceTableData
          );
        })
        .catch((error) => {
          console.warn(error);
        });
    }
    void fetchData();
  }, []);
  return (
    <>
      <OutcomeStatuses outcomeStatusData={opportunitiesStatus} />
      <div className="driver-table-main">
        <p className="title">Opportunities to improve performance</p>
        <Table
          loading={!opportunitiesToImproveTableData?.length}
          columns={columns}
          dataSource={opportunitiesToImproveTableData}
        />
      </div>
    </>
  );
};
export default OpportunitiesToImprovePerformance;
