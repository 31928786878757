import * as amplitude from "@amplitude/analytics-browser";
import { useCallback } from "react";
import { useAuth } from "../../lib/auth/AuthContext";

const getDomainFromEmail = (email: string): string => {
  const parts = email.split("@");
  return parts.length > 1 ? parts[parts.length - 1] : "";
};

export const useAmplitudeLogging = (name: string): any => {
  const { user } = useAuth();
  const emit = useCallback(
    async (additionalData?: Record<string, unknown>) => {
      const payload = Object.freeze({
        user: {
          ID: user?.id,
          email: getDomainFromEmail(user.email),
        },
        time: new Date(),
        ...additionalData,
      });
      const eventName = additionalData?.event?.toString() ?? name;
      if (eventName) {
        await amplitude
          .logEvent(eventName, payload)
          .promise.then((res) => {
            return res;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    [user?.id, name]
  );

  return emit;
};
