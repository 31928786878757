import { Modal } from "antd";
import { type ColumnsType } from "antd/es/table";
import React, { type MouseEvent } from "react";
import { Typography } from "syngenta-digital-cropwise-react-ui-kit";
import BlueDot from "../../assets/images/blue-dot.svg";
import FactCheck from "../../assets/images/fact-check.svg";
import GreenDot from "../../assets/images/green-dot.svg";
import GreyDot from "../../assets/images/grey-dot.svg";
import { STATUS } from "../../constants/keys";
import Table from "../../shared/components/Table";
import useAxios from "../../shared/custom-hooks/useAxios";
import "../style.less";
import { type SubmittedAssessmentDataType } from "./types";

interface SubmittedAssesmentsProps {
  onCancel: (event: MouseEvent<HTMLElement>) => void;
  reportTitle: string;
  partnerId?: string;
  cropYear: string;
}

const columns: ColumnsType<SubmittedAssessmentDataType> = [
  {
    title: <div style={{ fontWeight: "bold" }}>Crop</div>,
    dataIndex: "crop",
    key: "crop",
    sorter: (a: any, b: any) =>
      a.crop.toLowerCase() < b.crop.toLowerCase() ? -1 : 1,
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "ascend",
  },
  {
    title: <div style={{ fontWeight: "bold" }}>User ID</div>,
    dataIndex: "userId",
    key: "userId",
    sorter: (a: any, b: any) => a.userId - b.userId,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: <div style={{ fontWeight: "bold" }}>Status</div>,
    dataIndex: "status",
    key: "status",
    sorter: (a, b) =>
      a.status.toLowerCase() < b.status.toLowerCase() ? -1 : 1,
    sortDirections: ["ascend", "descend", "ascend"],
    render: (record) => {
      const statusIcon =
        record === STATUS.IN_PROGRESS
          ? BlueDot
          : record === STATUS.NOT_STARTED
          ? GreyDot
          : GreenDot;
      return (
        <>
          <img src={statusIcon} />
          &nbsp; <Typography.Text>{record}</Typography.Text>
        </>
      );
    },
  },
];

export default function SubmittedAssesments({
  onCancel,
  reportTitle,
  partnerId,
  cropYear,
}: SubmittedAssesmentsProps): JSX.Element {
  const { loading, response } = useAxios(
    `/plan/${String(partnerId)}/${cropYear}`
  );

  const { totalRecords, response: submittedAssessmentDetails } = response || {};

  return (
    <Modal
      destroyOnClose={true}
      width={550}
      title={
        <div className="modal-title">
          <img
            src={FactCheck}
            alt="Fact Check Icon"
            style={{ marginRight: 8 }}
          />
          Submitted assessments
        </div>
      }
      centered
      open={true}
      closable={true}
      keyboard={false}
      footer={null}
      className="submit-assessment"
      onCancel={onCancel}
    >
      <Table
        loading={loading}
        className="submit-assessment-table"
        tableTitle={reportTitle}
        searchBox={true}
        columns={columns}
        dataSource={submittedAssessmentDetails}
        pagination={{
          total: totalRecords,
          pageSize: 5,
        }}
      />
    </Modal>
  );
}
