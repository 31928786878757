import { blue } from "../../constants/colorsPallete";

export const getSaiPerformanceAndAverage = (saiData: any): any => {
  const result = {
    not_yet_bronze: 0,
    bronze: 0,
    silver: 0,
    gold: 0,
  };
  const avg: any = {
    essential: 0,
    intermediate: 0,
    advanced: 0,
  };
  const { data, totalFarmers } = saiData;
  data.forEach((plan: any) => {
    const score = plan.fsaScore;
    const essentialPercentage: number = score.essential?.percentage
      ? parseFloat(score.essential.percentage)
      : 0;
    const intermediatePercentage = score.intermediate?.percentage
      ? parseFloat(score.intermediate.percentage)
      : 0;
    const advancedPercentage = score.advanced?.percentage
      ? parseFloat(score.advanced.percentage)
      : 0;
    avg.essential = parseFloat(avg.essential) + essentialPercentage;
    avg.intermediate = parseFloat(avg.intermediate) + intermediatePercentage;
    avg.advanced = parseFloat(avg.advanced) + advancedPercentage;
    if (essentialPercentage === 100) {
      if (intermediatePercentage === 100 && advancedPercentage >= 75) {
        result.gold++;
      } else if (intermediatePercentage >= 75 && advancedPercentage >= 50) {
        result.silver++;
      } else if (intermediatePercentage >= 50) {
        result.bronze++;
      } else {
        result.not_yet_bronze++;
      }
    } else {
      result.not_yet_bronze++;
    }
  });

  const dataset: number[] = [];
  const labels: string[] = [];
  Object.keys(avg).forEach((key: string) => {
    labels.push(`Average of ${key}`);
    dataset.push(parseFloat((avg[key] / totalFarmers).toFixed(2)));
  });
  const saiAverage = {
    labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: blue["50"],
      },
    ],
  };

  const saiPerformance = {
    labels: ["Not yet bronze", "Bronze", "Silver", "Gold"],
    datasets: [
      {
        data: [
          result.not_yet_bronze,
          result.bronze,
          result.silver,
          result.gold,
        ],
        backgroundColor: blue["50"],
      },
    ],
  };
  return {
    saiPerformance,
    saiAverage,
  };
};
