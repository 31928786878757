import { createContext, type ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAxios from "../../../shared/custom-hooks/useAxios";
import { useCallAmplitudeEvent } from "../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getSaiPerformanceAndAverage } from "../../../transformer/sai-indicator/sai-performance";
import { APP_MODULES } from "../../amplitude-event-constants";
import { type GenericDataType } from "../management-practices/types";

export const SaiIndicatorDataSetContext = createContext<GenericDataType>({});
export const SaiIndicator = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const { loading, response } = useAxios(
    `/report/sai-indicator/${String(partnerId)}/${String(cropYear)}`
  );
  const saiIndicator = loading ? {} : getSaiPerformanceAndAverage(response);
  useCallAmplitudeEvent(APP_MODULES.SAI_INDICATOR);

  return (
    <SaiIndicatorDataSetContext.Provider
      value={{
        saiIndicator,
        loading,
        saiIndicatorApiResponse: response,
      }}
    >
      <Outlet />
    </SaiIndicatorDataSetContext.Provider>
  );
};
