import { Button } from "antd";
import {
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Row, Typography } from "syngenta-digital-cropwise-react-ui-kit";
import RightArrow from "../../assets/images/chevron_right.svg";
import { ReactComponent as NextIcon } from "../../assets/images/next.svg";
import {
  TAB_SECTION_TITLES,
  type TabContent,
  type TabDetails,
} from "../../constants/keys";
import { LeftNavigator } from "../../shared/components/LeftNavigationBar";
import "../../shared/components/style.less";
import { useAmplitudeLogging } from "../../shared/custom-hooks/useAmplitudeTracking";
import "../style.less";
import "./style.less";

const LeftNavigatorItems = ({
  tabKey,
  setSelectedTabContentKey,
}: {
  tabKey: string;
  setSelectedTabContentKey: Dispatch<SetStateAction<string | null>>;
}): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { partnerId, cropYear } = useParams();
  const tabDetails = useMemo(() => {
    return TAB_SECTION_TITLES.find(
      (tabDetails) => tabDetails.key === tabKey
    ) as TabDetails;
  }, [tabKey]);

  const onClickHandler = (route: string): void => {
    if (route) {
      setSelectedTabContentKey(route);
      navigate(
        `../partners/${partnerId as string}/${cropYear as string}/${route}`
      );
    }
  };
  return (
    <LeftNavigator>
      {tabDetails?.content?.map((tab: TabContent, index) => {
        const isActive =
          tab.route && pathname.includes(tab.route) ? "active" : "";
        return (
          <Card
            className="left-navigator"
            key={tab.title}
            tabIndex={index}
            onClick={() => {
              onClickHandler(tab.route);
            }}
          >
            <Row
              className={`left-navigator-card ${isActive} justify-content__space-between card-border`}
            >
              <div className="navigator-title-section">
                <div className={`icon-background ${isActive}`}>
                  <img
                    src={tab.icon}
                    className="left-navigation--tab--start-icon"
                    alt="start-icon"
                  />
                </div>
                <Typography.Text className="left-navigator__item-text">
                  {tab.title}
                </Typography.Text>
              </div>
              <img src={RightArrow} alt="right-arrow" />
            </Row>
          </Card>
        );
      })}
    </LeftNavigator>
  );
};

export const TopNavigationTabs = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [selectedTabKey, setSelectedTabKey] = useState(
    TAB_SECTION_TITLES[0].key
  );
  const [selectedTabContentKey, setSelectedTabContentKey] = useState<
    string | null
  >(null);
  const { pathname } = useLocation();
  const { partnerId, cropYear } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const pathnameArray = pathname.split("/");
    const selectedTab = TAB_SECTION_TITLES.find(
      (tab) => tab.key === pathnameArray[5]
    );
    selectedTab && setSelectedTabKey(selectedTab.key);
    selectedTab && setSelectedTabContentKey(pathnameArray[6]);
  }, [pathname]);

  const renderLeftNavigator = (tab: TabDetails): void => {
    tab.content &&
      navigate(
        `../partners/${partnerId as string}/${cropYear as string}/${
          tab.content[0].route
        }`
      );
    setSelectedTabKey(tab.key);
  };

  const [currentIndex, currentTab, selectedTabContentIndex] = useMemo(() => {
    const currentIndex = TAB_SECTION_TITLES.findIndex(
      (tab) => tab.key === selectedTabKey
    );
    const currentTab = TAB_SECTION_TITLES[currentIndex];
    const selectedTabContentIndex = currentTab
      ? currentTab.content.findIndex(
          (item) =>
            selectedTabContentKey &&
            item?.route?.includes(selectedTabContentKey)
        )
      : -1;
    return [currentIndex, currentTab, selectedTabContentIndex];
  }, [selectedTabKey, selectedTabContentKey]);

  const handleBackNextButtons = (
    modifiedCurrentIndex: number,
    modifiedSelectedTabContentIndex: number
  ): void => {
    if (currentIndex >= 0 && currentTab) {
      const newContent = currentTab.content[modifiedSelectedTabContentIndex];
      if (!newContent) {
        const previousTab = TAB_SECTION_TITLES[modifiedCurrentIndex];
        setSelectedTabKey(previousTab.key);
        navigateNext(previousTab.content[0].route);
      } else {
        navigateNext(newContent.route);
      }
    }
  };

  const handleGoBack = (): void => {
    handleBackNextButtons(currentIndex - 1, selectedTabContentIndex - 1);
  };
  const navigateNext = (route: string): void => {
    setSelectedTabContentKey(route);
    if (route) {
      navigate(
        `../partners/${partnerId as string}/${cropYear as string}/${route}`
      );
    }
  };

  const eventName = "Next button";
  const trackEvents = useAmplitudeLogging(eventName);
  const handleNext = (): void => {
    trackEvents({ event: eventName });
    handleBackNextButtons(currentIndex + 1, selectedTabContentIndex + 1);
  };
  const currentTabContent: TabContent[] = currentTab.content;
  const lastTabContent: TabContent[] = TAB_SECTION_TITLES.slice(-1)[0].content;
  const lastTabContentRoute = lastTabContent.slice(-1)[0].route;
  const disabledNextButton =
    currentTabContent[selectedTabContentIndex]?.route === lastTabContentRoute;

  return (
    <div className="navigate">
      <div className="top-tab-box">
        <Typography.Title className="tab-title">
          Navigate categories
        </Typography.Title>
        <div className="tab-section">
          {TAB_SECTION_TITLES.map((tabDetails, index) => {
            const isActive = selectedTabKey === tabDetails.key ? "active" : "";
            return (
              <Link
                id={tabDetails.key}
                className={`tab-main ${isActive}`}
                key={tabDetails.tabName}
                tabIndex={index}
                to={tabDetails.content[0].route}
                onClick={() => {
                  renderLeftNavigator(tabDetails);
                }}
              >
                <img
                  src={tabDetails.icon}
                  className={`icon-background ${isActive}`}
                  alt="top-icon"
                />
                <Typography.Text>{tabDetails.tabName}</Typography.Text>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="tab-container" id="top-navigation-tabs">
        <LeftNavigatorItems
          tabKey={selectedTabKey}
          setSelectedTabContentKey={setSelectedTabContentKey}
        />
        <div className="content-container">
          <div className="content-overflow-container">
            <div className="content-main">{children}</div>
            <div className="btn-main">
              <Button
                className="go-back"
                onClick={handleGoBack}
                disabled={currentIndex === 0 && selectedTabContentIndex === 0}
              >
                Go back
              </Button>
              <Button
                className="next-btn"
                onClick={handleNext}
                disabled={disabledNextButton}
              >
                <NextIcon /> Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
