import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import React, { type ReactElement } from "react";
import { blue, neutral } from "../../../../constants/colorsPallete";
import HorizontalBarChart from "../../../../shared/components/HorizontalBarChart";
import { calculateRanges } from "../../management-practices/utils";
import { type FarmsDetail } from "../types";
import { farmsDistributionLevel } from "../utils";
const FarmsAcresDistribution = ({
  farmsDetails,
}: {
  farmsDetails: FarmsDetail[];
}): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck >= 3 ? "end" : "center";
          }
          return "center";
        },
        align: (context) => {
          const dataValue = context.dataset?.data[context.dataIndex];
          if (
            typeof dataValue === "number" ||
            (Array.isArray(dataValue) && typeof dataValue[0] === "number")
          ) {
            const valueToCheck = Array.isArray(dataValue)
              ? dataValue[0]
              : dataValue;
            return valueToCheck === 0
              ? "end"
              : valueToCheck >= 3
              ? "start"
              : "center";
          }
          return "center";
        },
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "#fff" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Farmers",
          color: neutral["70"],
          font: {
            size: 14,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
      },

      y: {
        title: {
          text: "Count of area",
          color: neutral["70"],
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
      },
    },
  };

  const totalAreaData: number[] = farmsDetails.map((framer) =>
    parseInt(framer.totalArea)
  );

  const isSingleGrower = farmsDetails.length === 1;
  const ranges: string[] = calculateRanges(totalAreaData);

  const levelCounts = farmsDistributionLevel(farmsDetails, ranges);

  const FarmsDistributionDataSet = {
    labels: isSingleGrower ? [totalAreaData[0].toFixed(2)] : ranges,
    datasets: [
      {
        label: "Total Area",
        data: isSingleGrower ? [1] : levelCounts,
        backgroundColor: blue["70"],
      },
    ],
  };

  return (
    <HorizontalBarChart options={options} data={FarmsDistributionDataSet} />
  );
};
export default FarmsAcresDistribution;
