import { forwardRef, useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import {
  ChoroplethController,
  GeoFeature,
  ColorScale,
  ProjectionScale,
} from "chartjs-chart-geo";
import { feature } from "topojson-client";
import countries50m from "world-atlas/countries-50m.json";

Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);

const WorldGeoMapChart = forwardRef<
  HTMLCanvasElement,
  { chartData: Record<string, number> }
>(({ chartData }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [countries, setCountries] = useState<any>([]);

  useEffect(() => {
    const countriesData = (
      feature(countries50m as any, countries50m.objects.countries as any) as any
    ).features;
    setCountries(countriesData);
  }, []);

  useEffect(() => {
    if (!chartRef?.current || !countries?.length) return;

    // Destroy the previous Chart instance
    const existingChart = Chart.getChart(chartRef.current);
    if (existingChart) {
      existingChart.destroy();
    }

    const dataset = {
      labels: countries.map(
        (d: { properties: { name: string } }) => d.properties.name
      ),
      datasets: [
        {
          label: "Countries",
          data: countries.map((d: any) => ({
            feature: d,
            value: chartData?.[d.properties.name] || 0,
          })),
        },
      ],
    };

    new Chart(chartRef.current, {
      type: "choropleth",
      data: dataset,
      options: {
        showOutline: true,
        // showGraticule: true,
        plugins: {
          title: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          projection: {
            axis: "x",
            projection: "equalEarth",
          },
        },
      },
    });
  }, [chartData, chartRef, countries]);

  return (
    <div>
      <canvas id="canvas" ref={chartRef}></canvas>
    </div>
  );
});
WorldGeoMapChart.displayName = "WorldGeoMapChart";

export default WorldGeoMapChart;
