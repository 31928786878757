import React, { type ReactElement } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  type ChartOptions,
  type ChartData,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
interface ScatterBarChartProps {
  // label: string;
  data: ChartData<"scatter">;
  options?: ChartOptions<"scatter">;
  width?: number;
  height?: number;
  redraw?: boolean;
}
function ScatterChart({
  data,
  options = {},
  width,
  height,
  ...props
}: ScatterBarChartProps): ReactElement {
  options.responsive = options?.responsive ?? true;

  options.plugins = {
    ...options?.plugins,
    title: {
      align: "start", // all the charts display title on start
      ...options?.plugins?.title,
      display:
        options?.plugins?.title?.display ?? !!options?.plugins?.title?.text,
    },
    legend: {
      display: false, // default legend to false as most of the chart don't legends
      position: "top",
      ...options?.plugins?.legend,
    },
  };

  options.scales = {
    ...options.scales,
    x: {
      ...options.scales?.x,
      title: {
        display: true,
        ...options.scales?.x?.title,
      },
    },
    y: {
      ...options.scales?.y,
      title: {
        display: true,
        ...options.scales?.y?.title,
      },
    },
  };

  return (
    <Scatter
      data={data}
      options={options}
      width={width}
      height={height}
      {...props}
    />
  );
}
export default ScatterChart;
