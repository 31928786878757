import _ from "lodash";
import { green } from "../../constants/colorsPallete";
import { getStatus } from "../../pages/reports/summary/utils";

export const getOverallLeadershipLevel = (data: any): any => {
  const { userActivityScore } = data;
  const growerScoreMap = new Map();
  const driverSet = new Set();
  const levelCounts: any = {
    essential: 0,
    basic: 0,
    medium: 0,
    high: 0,
  };
  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return item.planId;
    })
    .forEach((value, key) => {
      let totalScore = 0;
      value.forEach((val) => {
        driverSet.add(val.driverNumber);
        totalScore += val.score as number;
      });
      growerScoreMap.set(key, totalScore);
    })
    .value();

  growerScoreMap.forEach((val, key) => {
    const score = val / driverSet.size;
    const status = getStatus(score);
    levelCounts[status.toLowerCase()]++;
  });
  return {
    labels: ["Essential", "Basic", "Medium", "High"],
    datasets: [
      {
        label: "Growers",
        data: [
          levelCounts.essential,
          levelCounts.basic,
          levelCounts.medium,
          levelCounts.high,
        ],
        backgroundColor: green["50"],
      },
    ],
  };
};
