import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { useContext, type ReactElement } from "react";
import { SaiIndicatorDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { InlineLoader } from "../../../../shared/components/Loader";
import VerticalBarChart from "../../../../shared/components/VerticalBarChart";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";

const AverageScores = (): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: (context) =>
          context.dataset.data[context.dataIndex] === 0 ? "end" : "start",
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Question type",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 16,
          },
          color: neutral["50"],
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          text: "Percentage %",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 20,
          color: neutral["50"],
          font: {
            size: 16,
          },
        },
      },
    },
  };

  const {
    saiIndicator: { saiAverage },
    loading,
  } = useContext(SaiIndicatorDataSetContext);

  const eventName = `${APP_MODULES.SAI_INDICATOR}_${APP_MODULES.AVERAGE_SCORES}`;
  useCallAmplitudeEvent(eventName);

  return (
    <div className="sai-performance overview-context">
      <div className="ant-card-bordered">
        <p className="title">Average scores by SAI question type</p>
        {loading ? (
          <InlineLoader
            text="Loading graph..."
            top="20vh"
            position="relative"
          />
        ) : (
          <VerticalBarChart options={options} data={saiAverage} />
        )}
      </div>
    </div>
  );
};

export default AverageScores;
