import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
  type ChartData,
  type ChartOptions,
} from "chart.js";
import React, { type ReactElement } from "react";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
interface PieChartProps {
  data: ChartData<"pie">;
  options?: ChartOptions<"pie">;
  width?: number;
  height?: number;
  redraw?: boolean;
}
function PieChart({
  data,
  options = {},
  width,
  height,
  ...props
}: PieChartProps): ReactElement {
  options.responsive = options?.responsive ?? true;

  options.plugins = {
    ...options?.plugins,
    title: {
      align: "start", // all the charts display title on start
      ...options?.plugins?.title,
      display:
        options?.plugins?.title?.display ?? !!options?.plugins?.title?.text,
    },
    legend: {
      position: "right", // keeping default as right, as the that is most used one in reports
      ...options?.plugins?.legend,
    },
  };

  return (
    <Pie
      data={data}
      options={options}
      width={width}
      height={height}
      {...props}
    />
  );
}
export default PieChart;
