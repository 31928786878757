export const LEADERSHIP_USER_PERFORMANCE = {
  ESSENTIAL: { title: "Essential", upperLimit: 0.75 },
  BASIC: { title: "Basic", lowerLimit: 0.75, upperLimit: 1.5 },
  MEDIUM: { title: "Medium", lowerLimit: 1.5, upperLimit: 2.25 },
  HIGH: { title: "High", lowerLimit: 2.25 },
};
export const LEADERSHIP_LEVELS = ["Essential", "Basic", "Medium", "High"];
export const IRRIGATION_TYPES = {
  irrigated: "irrigated",
  rainfed: "rainfed",
};
export const WATER_IMPACT_OUTCOME_NUMBER = "6.0.0";
export const WATER_MANAGEMENT_DRIVER_NUMBER = "6.3.0";
export const WATER_IMPACT_TYPES = {
  irrigated: "Water Impact - irrigated",
  rainfed: "Water Impact - rainfed",
};
export const MANAGEMENT_PRACTICES: any = {
  "nutrient-optimal-production": ["7.8.1"],
  "nutrient-long-term-system-resilience": ["7.8.2"],
  "nutrient-environmental-stewardship": ["7.8.3"],
  "pest-optimal-production": ["7.9.1", "7.9.2"],
  "pest-human-animal-health": ["7.9.3"],
  "pest-long-term-system-resilience": ["7.9.4", "7.9.5"],
  "pest-environmental-stewardship": ["7.9.6", "7.9.7"],
  "days-with-living-root": ["7.3.3"],
  "cover-crops-grown": ["7.3.1"],
  "soil-health-1": [
    "7.2.1",
    "7.2.2",
    "7.2.3",
    "7.2.4",
    "7.2.5",
    "7.2.8",
    "7.2.9",
  ],
  "soil-health-2": ["7.2.4", "7.2.5", "7.2.8", "7.2.9"],
  "soil-livestock-graze": ["7.7.2"],
  "soil-livestock-species": ["7.7.1"],
  "biodiversity-habitat-conservation": [
    "7.2.1",
    "7.2.2",
    "7.2.3",
    "7.2.4",
    "7.2.5",
    "7.2.6",
    "7.2.7",
  ],
  tillage: ["7.12.1"],
};
export const MANAGEMENT_PRACTICE_QUESTIONS = [
  "7.3.1",
  "7.3.3",
  "7.8.1",
  "7.8.2",
  "7.8.3",
  "7.9.1",
  "7.9.2",
  "7.9.3",
  "7.9.4",
  "7.9.5",
  "7.9.6",
  "7.9.7",
  "7.2.1",
  "7.2.2",
  "7.2.3",
  "7.2.4",
  "7.2.5",
  "7.2.6",
  "7.2.7",
  "7.2.8",
  "7.2.9",
  "7.7.1",
  "7.7.2",
  "7.12.1",
];
export const TILLAGE_QUESTION = "7.12.1";
export const NONE_OF_THE_ABOVE_LABELS = [
  "None of the above",
  "None",
  "Nenhuma das opções acima",
  "Nenhum",
  "Ninguna de las anteriores",
  "Aucune des réponses ci-dessus",
  "A fentiek közül egyik sem",
];
export const PRACTICE_SOIL_HEALTH: any = {
  "7.2.8": "Participated in a soil health field day",
  "7.2.9": "Participated in soil health training",
  "7.2.7": "Use a formal conservation plan",
};
export const PRACTICE_SOIL_HEALTH_QUESTIONS = ["7.2.7", "7.2.8", "7.2.9"];
export const SAI_INDICATOR_LEVELS = {
  ESSENTIAL: "essential",
  INTERMEDIATE: "intermediate",
  ADVANCED: "advanced",
};
export const CONTINUOUS_NO_TILL = "7.12.2";
export const BIODIVERSITY_AND_HABITAT_PRACTICES = [
  "7.2.1::2",
  "7.2.1::3",
  "7.2.1::4",
  "7.2.1::5",
  "7.2.3::4",
  "7.2.3::5",
  "7.2.7::1",
  "7.2.4::3",
  "7.2.4::4",
  "7.2.6::9",
  "7.2.5::1",
  "7.2.5::2",
];
export const LOCALE_EN = "en";
export const BIODIVERSITY_HABITAT = "biodiversity-habitat-conservation";
export const NITROGEN_FERTILIZERS = [
  "7.5.2::1",
  "7.5.2::2",
  "7.5.2::3",
  "7.5.2::4",
  "7.5.3::1",
  "7.5.3::2",
  "7.5.3::3",
  "7.5.3::4",
  "7.5.3::5",
];
export const NITROGEN_QUESTIONS = ["7.5.2", "7.5.3"];
