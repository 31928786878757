import axios from "axios";

const token = (): string => localStorage.getItem("tokens") ?? "";
export const getAccessToken = (): string => {
  try {
    return JSON.parse(token()).access_token;
  } catch (e) {
    return "";
  }
};

const UNAUTHORIZED_ERROR_CODE = 401;

const URL = process.env.REACT_APP_API_URL ?? "";
const environment = process.env.REACT_APP_ENV ?? "";
const version = process.env.REACT_APP_VERSION ?? "";

const baseURL = `${URL}/${environment}/${version}`;

export const AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

AxiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    // Do something before request is sent
    return config;
  },
  async function (error) {
    // Do something with request error
    return await Promise.reject(error);
  }
);
AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
      //   authService.getAccessToken();
      // void logout();
    }
  }
);

export default AxiosInstance;
