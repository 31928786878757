import _ from "lodash";
import { LOCALE_EN } from "../constants";

type numberMap = Record<string, any>;

export const getOpportunityToImprove = (data: any, opportunity: any): any => {
  const { userActivityScore, totalFarmers, languages } = data;
  const driverNumberMap = data.driverNumberMap as numberMap;
  const outcomeNumberMap = data.outcomesNumberMap as numberMap;
  const oneThirdFarmers = Math.floor(totalFarmers / 3);
  const driverMap = new Map();
  const levels = ["Essential", "Basic", "Medium", "High"];

  _.chain(userActivityScore)
    .groupBy((item: any) => {
      return `${item.outcomeNumber.trim() as string}::${
        item.driverNumber.trim() as string
      }`;
    })
    .forEach((value, key) => {
      const scores: any = {
        Essential: 0,
        Basic: 0,
        Medium: 0,
        High: 0,
      };
      value.forEach((userActivity: any) => {
        scores[levels[userActivity.score]]++;
      });
      levels.forEach((val: any, index: number) => {
        if (!driverMap.has(key)) {
          if (
            index !== 3 &&
            scores[val] !== 0 &&
            scores[val] >= oneThirdFarmers
          ) {
            driverMap.set(key, index);
          }
        }
      });
    })
    .value();
  const sortedDriverMap = new Map(
    [...driverMap.entries()].sort((a, b) => a[1] - b[1])
  );
  const entries = [...sortedDriverMap.keys()].slice(0, 5);
  const result: any = [];
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];
  entries.forEach((key) => {
    const credit = driverMap.get(key);
    const names: string[] = key.split("::");
    const outcomeName: string = outcomeNumberMap[names[0]][locale];
    const driverName: string = driverNumberMap[names[1]][locale];

    const opp = _.find(opportunity[driverName], { credit });
    if (opp) {
      result.push({
        key: names[1],
        outcomeDriver: `${outcomeName} - ${driverName}`,
        currentLevel: levels[credit],
        opportunityForImprovement: opp.recommendation,
      });
    }
  });
  return result;
};
