import { Card, Col, Row } from "antd";
import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import React, { useContext, type ReactElement } from "react";
import { SaiIndicatorDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { getSaiFarmersQuestions } from "../../../../transformer/sai-indicator/farmers-questions";
import { APP_MODULES } from "../../../amplitude-event-constants";
import "../../../style.less";
import { GetGraphComponent } from "../../management-practices/getGraphComponent";
import { type GraphDetailsType } from "../../management-practices/types";
import FarmersByPercentageOfQuestions from "./FarmersByPercentageOfQuestions";
import FarmersFsaQuestion from "./FarmersFsaQuestion";

export const Farmers = (): ReactElement => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        align: (context) =>
          context.dataset?.data[context.dataIndex] === 1 ? "center" : "right",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? "white" : neutral["100"];
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? "white" : neutral["100"];
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Frequency",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 2,
          color: neutral["50"],
          font: {
            size: 12,
          },
        },
      },
      y: {
        suggestedMin: 2,
        title: {
          text: "Farmers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: neutral["50"],
        },
      },
    },
  };
  const { saiIndicatorApiResponse, loading } = useContext(
    SaiIndicatorDataSetContext
  );
  const farmerData = loading
    ? {}
    : getSaiFarmersQuestions(saiIndicatorApiResponse);
  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Farmers by % of essential questions",
      graph: (
        <FarmersByPercentageOfQuestions
          graphLabel="Essential Level"
          farmerByQuestionData={farmerData?.farmersCount?.essential}
          options={options}
        />
      ),
      loading,
    },
    {
      title: "Farmers by % of intermediate questions",
      graph: (
        <FarmersByPercentageOfQuestions
          graphLabel="Intermediate Level"
          farmerByQuestionData={farmerData?.farmersCount?.advanced}
          options={options}
        />
      ),
      loading,
    },
    {
      title: "Farmers by % of advanced questions",
      graph: (
        <FarmersByPercentageOfQuestions
          graphLabel="Advanced Level"
          farmerByQuestionData={farmerData?.farmersCount?.intermediate}
          options={options}
        />
      ),
      loading,
    },
  ];

  const eventName = `${APP_MODULES.SAI_INDICATOR}_${APP_MODULES.FARMERS_BY_PERCENTAGE_OF_QUESTIONS}`;
  useCallAmplitudeEvent(eventName);

  return (
    <>
      <Row gutter={16} className="overview-context">
        {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
          return GetGraphComponent(
            { ...graphDetails, waterQuality: true },
            index
          );
        })}
        <Col span={24}>
          <Card className="overview-context-farms">
            <h3>Percentage of farmers achieving FSA essential questions</h3>
            <FarmersFsaQuestion />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Farmers;
