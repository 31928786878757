import { type ReactElement } from "react";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import CommonOutcomesModule from "../CommonOutcomesModule";

const HumanAndAnimalHealth = (): ReactElement => {
  const eventName = `${APP_MODULES.OUTCOMES}_${APP_MODULES.HUMAN_AND_ANIMAL_HEALTH}`;
  useCallAmplitudeEvent(eventName);

  return (
    <CommonOutcomesModule
      outcomeKey="3.0.0"
      outcomeName="Human & animal health levels"
      horizontalChart={true}
    />
  );
};

export default HumanAndAnimalHealth;
