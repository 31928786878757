import { Breadcrumb } from "antd";
import React, { type FC, type ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.less";

interface ProviderProp {
  children: ReactNode;
  moduleTitle?: string;
  partnerName?: string;
}

export const AppBreadcrumb = ({
  moduleTitle,
  partnerName,
}: {
  moduleTitle: string | undefined;
  partnerName: string | undefined;
}): JSX.Element => {
  const navigate = useNavigate();
  const { partnerId, cropYear } = useParams();
  return (
    <Breadcrumb
      className="breadcrumb"
      items={[
        {
          title: "Partner's List",
          onClick: () => {
            navigate("/app/partners", { state: { partnerId, cropYear } });
          },
        },
        { title: partnerName },
        { title: moduleTitle },
      ]}
    />
  );
};

export const TopBreadcrumb: FC<ProviderProp> = ({
  children,
  moduleTitle,
  partnerName,
}): JSX.Element => {
  return (
    <>
      <AppBreadcrumb moduleTitle={moduleTitle} partnerName={partnerName} />
      {children}
    </>
  );
};
