import { Row } from "antd";
import { type BubbleDataPoint, type ChartOptions, type Point } from "chart.js";
import React, { useContext, type ReactElement } from "react";
import {
  type IBubbleMapDataPoint,
  type IChoroplethDataPoint,
} from "chartjs-chart-geo";
import { ManagementPracticeDataSetContext } from "..";
import { neutral } from "../../../../constants/colorsPallete";
import { GetGraphComponent } from "../getGraphComponent";
import { type GraphDetailsType } from "../types";
import EnvironmentalStewardship from "./EnvironmentalStewardship";
import HumanAndAnimalHealth from "./HumanAndAnimalHealth";
import LongTermSystemResilience from "./LongTermSystemResilience";
import OptimalProduction from "./OptimalProduction";

const PestManagement = (): ReactElement => {
  const { answerApiLoading, answerApiTransformedData } = useContext(
    ManagementPracticeDataSetContext
  );

  const options: ChartOptions<"bar"> = {
    responsive: true,
    animation: false,
    plugins: {
      datalabels: {
        align: (context) =>
          context.dataset?.data[context.dataIndex] === 1 ? "center" : "right",
        formatter: (value) => (value >= 1 ? value : 0),
        color: (context) => {
          const dataValue:
            | number
            | [number, number]
            | Point
            | BubbleDataPoint
            | IBubbleMapDataPoint
            | IChoroplethDataPoint
            | null = context.dataset?.data[context.dataIndex];

          if (typeof dataValue === "number") {
            return dataValue >= 1 ? neutral["100"] : "black";
          }
          if (Array.isArray(dataValue)) {
            const [firstValue] = dataValue;
            return firstValue >= 1 ? neutral["100"] : "black";
          }
          return neutral["100"];
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 2,
          color: neutral["50"],
        },
      },
      y: {
        title: {
          text: "Pest management practices",
          color: neutral["70"],
          font: {
            size: 16,
          },
          padding: 0,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Optimal production",
      graph: (
        <OptimalProduction
          growersDetails={answerApiTransformedData["pest-optimal-production"]}
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Long term system resilience",
      graph: (
        <LongTermSystemResilience
          growersDetails={
            answerApiTransformedData["pest-long-term-system-resilience"]
          }
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Environmental stewardship",
      graph: (
        <EnvironmentalStewardship
          growersDetails={
            answerApiTransformedData["pest-environmental-stewardship"]
          }
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Human and animal health",
      graph: (
        <HumanAndAnimalHealth
          growersDetails={answerApiTransformedData["pest-human-animal-health"]}
          options={{ ...options }}
        />
      ),
      loading: answerApiLoading,
    },
  ];

  return (
    <Row gutter={16} className="overview-context">
      {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
        return GetGraphComponent({ ...graphDetails }, index);
      })}
    </Row>
  );
};

export default PestManagement;
