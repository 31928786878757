import {
  BIODIVERSITY_AND_HABITAT_PRACTICES,
  BIODIVERSITY_HABITAT,
  CONTINUOUS_NO_TILL,
  LOCALE_EN,
  MANAGEMENT_PRACTICES,
  MANAGEMENT_PRACTICE_QUESTIONS,
  NONE_OF_THE_ABOVE_LABELS,
  PRACTICE_SOIL_HEALTH,
  PRACTICE_SOIL_HEALTH_QUESTIONS,
  TILLAGE_QUESTION,
} from "../constants";
export const getPracticeCount = (data: any): any => {
  const { totalFarmers, languages } = data;
  const result: any = {};

  const { labelAnsMap, labelIdMap, continousNoTillMap } =
    getAnswersLabelsMaps(data);
  const locale = languages.includes(LOCALE_EN) ? LOCALE_EN : languages[0];
  Object.keys(MANAGEMENT_PRACTICES).forEach((key: string) => {
    const questionNumbers = MANAGEMENT_PRACTICES[key];
    const labelData: string[] = [];
    const countData: number[] = [];
    questionNumbers.forEach((num: string) => {
      const values = labelAnsMap.get(num);
      if (values) {
        values.forEach((count: number, labeld: string) => {
          if (num === TILLAGE_QUESTION) {
            count = parseInt((count / totalFarmers).toFixed(2));
          }
          if (labelIdMap[num][locale][labeld]) {
            if (key === BIODIVERSITY_HABITAT) {
              if (
                BIODIVERSITY_AND_HABITAT_PRACTICES.includes(`${num}::${labeld}`)
              ) {
                labelData.push(labelIdMap[num][locale][labeld]);
                countData.push(count);
              }
            } else {
              labelData.push(labelIdMap[num][locale][labeld]);
              countData.push(count);
            }
          }
        });
      }
    });
    result[key] = {
      labels: labelData,
      data: countData,
    };
  });
  result["continuous-no-till"] = continousNoTillMap;
  return result;
};

export const getAnswersLabelsMaps = (data: any): any => {
  const { answers, languages } = data;
  const labelAnsMap = new Map();
  const labelIdMap: any = {};
  const languageObj: any = {};
  languages.forEach((l: string) => {
    languageObj[l] = {};
  });
  MANAGEMENT_PRACTICE_QUESTIONS.forEach((key: any) => {
    labelAnsMap.set(key, new Map());
    labelIdMap[key] = JSON.parse(JSON.stringify(languageObj));
  });
  const continousNoTillMap: any = {};
  answers.forEach((answer: any) => {
    const { questionNumber } = answer;
    if (MANAGEMENT_PRACTICE_QUESTIONS.includes(questionNumber)) {
      populateMap(answer, labelAnsMap, labelIdMap);
    } else if (questionNumber === CONTINUOUS_NO_TILL) {
      answer?.label.forEach((label: any) => {
        const ans = label.answer !== null ? Math.floor(label.answer) : 0;
        if (continousNoTillMap[ans]) {
          continousNoTillMap[ans]++;
        } else {
          continousNoTillMap[ans] = 1;
        }
      });
    }
  });
  return { labelAnsMap, labelIdMap, continousNoTillMap };
};

export const countAnswers = (answer: any, questionNumber: string): number => {
  let ans = 0;
  if (answer !== null && answer) {
    if (questionNumber === TILLAGE_QUESTION) {
      ans = parseFloat(answer);
    } else {
      ans = 1;
    }
  }
  return ans;
};

export const populateMap = (
  answer: any,
  labelAnsMap: any,
  labelIdMap: any
): void => {
  answer?.label.forEach((label: any) => {
    if (
      !label.labelName &&
      PRACTICE_SOIL_HEALTH_QUESTIONS.includes(answer.questionNumber)
    ) {
      label.labelName = PRACTICE_SOIL_HEALTH[answer.questionNumber];
    }
    if (
      label.labelName &&
      !NONE_OF_THE_ABOVE_LABELS.includes(label.labelName)
    ) {
      const ans = countAnswers(label.answer, answer.questionNumber);
      const map = labelAnsMap.get(answer.questionNumber);
      let count: number = map.has(label.labelId) ? map.get(label.labelId) : 0;
      count += ans;
      map.set(label.labelId, count);
      labelIdMap[answer.questionNumber][answer.language][label.labelId] =
        label.labelName;
    }
  });
};
