import React, { type FC } from "react";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import { InlineErrorMessage, InlineLoader } from "../components/Loader";
import { type contentLoader } from "./types";

export const ContentLoader: FC<contentLoader> = ({
  children,
  isError = false,
  isLoading = false,
}): JSX.Element => {
  if (isLoading) {
    return <InlineLoader />;
  }
  if (isError) {
    return <InlineErrorMessage />;
  }
  return (
    <Layout className="page-content" id="scroll">
      {children}
    </Layout>
  );
};
