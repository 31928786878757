import { type ChartOptions } from "chart.js";
import { useContext, type ReactElement } from "react";
import { neutral } from "../../../constants/colorsPallete";
import VerticalBarChart from "../../../shared/components/VerticalBarChart";
import { getOutcomeDetails } from "../../../transformer/outcomes/outcome";
import "../../style.less";
import { OutcomeDataSetContext } from "../OutcomeDetails";
import OutcomesDrivers from "./OutcomesDrivers";

const CommonOutcomesModule = ({
  outcomeName,
  outcomeKey,
  horizontalChart,
}: {
  outcomeName: string;
  outcomeKey: string;
  horizontalChart?: boolean;
}): ReactElement => {
  const outcomeOverviewContext = useContext(OutcomeDataSetContext);

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        formatter: (value) => (value !== 0 ? value : ""),
        color: "white",
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          text: "Levels",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Growers",
          color: neutral["70"],
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  const outcomeDetails = getOutcomeDetails(outcomeOverviewContext);
  const drivers: Record<string, any> = Object.assign(
    {},
    ...outcomeDetails[outcomeKey].drivers
  );

  return (
    <div className="container-title outcomes-growers-container">
      <div className="outcomes-growers">
        <h4 className="outcomes-chart-title">Highlights</h4>
        <div className="outcomes-growes-chart">
          <p className="title">{outcomeName}</p>
          <VerticalBarChart
            options={options}
            data={outcomeDetails[outcomeKey]}
          />
        </div>
      </div>
      <div>
        <div className="outcomes-drivers-container">
          <h4 className="outcomes-chart-title">Details</h4>
          <OutcomesDrivers
            driverDetails={drivers}
            horizontalChart={horizontalChart}
          />
        </div>
      </div>
    </div>
  );
};

export default CommonOutcomesModule;
