import React, { type FC, type ReactNode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "../lib/auth/AuthContext";

interface ProviderProp {
  children: ReactNode;
}
const AppProviders: FC<ProviderProp> = ({ children }): JSX.Element => {
  return (
    <Router>
      <AuthProvider>
        {/* <ContextPovider>{children}</ContextPovider> */}
        {/* <GlobalProvider></GlobalProvider> */}
        {children}
      </AuthProvider>
    </Router>
  );
};
export default AppProviders;
