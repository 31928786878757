import React, { type ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  type ChartData,
  type ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface VerticalBarChartProps {
  data: ChartData<"bar">;
  options?: ChartOptions<"bar">;
  width?: number;
  height?: number;
  redraw?: boolean;
}

const VerticalBarChart = ({
  data,
  options = {},
  width = 400,
  height = 150,
  ...props
}: VerticalBarChartProps): ReactElement => {
  options.responsive = options?.responsive ?? true;

  options.scales = {
    ...options.scales,
    x: {
      ...options.scales?.x,
      title: {
        display: true,
        ...options.scales?.x?.title,
      },
    },
    y: {
      ...options.scales?.y,
      title: {
        display: true,
        ...options.scales?.y?.title,
      },
    },
  };

  options.plugins = {
    ...options?.plugins,
    legend: {
      display: false, // default legend to false as most of the chart don't legends
      ...options?.plugins?.legend,
    },
    title: {
      align: "start", // all the charts display title on start
      ...options?.plugins?.title,
      display:
        options?.plugins?.title?.display ?? !!options?.plugins?.title?.text,
    },
  };

  return (
    <Bar
      data-testid="vertical-bar-chart"
      data={data}
      options={options}
      width={width}
      height={height}
      {...props}
    />
  );
};

export default VerticalBarChart;
