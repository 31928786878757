/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { type ErrorInfo } from "react";
import "./style.less";

class ErrorBoundary extends React.Component<
  { children: any },
  { hasError: boolean; error: any; info: any }
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
    this.setState({ hasError: true, error, info });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h1 className="error-boundary-label">Something went wrong</h1>
          <p style={{ marginTop: 30 }}>{"Please try after some time"}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
