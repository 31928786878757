import { Col, Row } from "antd";
import React, { useContext, type ReactElement } from "react";
import { ManagementPracticeDataSetContext } from "..";
import { InlineLoader } from "../../../../shared/components/Loader";
import { useCallAmplitudeEvent } from "../../../../shared/custom-hooks/useLogAmplitudeEvents";
import { APP_MODULES } from "../../../amplitude-event-constants";
import { GetGraphComponent } from "../getGraphComponent";
import SoilHealth from "../soil-health/SoilHealth";
import { type GraphDetailsType } from "../types";
import ContinuousNoTillOrDirectDrill from "./ContinuousNoTillOrDirectDrill";
import CoverCropGrown from "./CoverCropsGrown";
import TillagePercentage from "./TillagePercentage";

const BiodiversityandHabitat = (): ReactElement => {
  const { answerApiLoading, answerApiTransformedData } = useContext(
    ManagementPracticeDataSetContext
  );

  const GRAPH_DETAILS: GraphDetailsType[] = [
    {
      title: "Tillage percentage",
      graph: (
        <TillagePercentage growersDetails={answerApiTransformedData.tillage} />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Continuous no-till or direct drill %",
      graph: (
        <ContinuousNoTillOrDirectDrill
          growersDetails={answerApiTransformedData["continuous-no-till"]}
        />
      ),
      loading: answerApiLoading,
    },
    {
      title: "Cover crops grown",
      graph: (
        <CoverCropGrown
          growersDetails={answerApiTransformedData["cover-crops-grown"]}
        />
      ),
      loading: answerApiLoading,
    },
  ];
  const eventName = `${APP_MODULES.MANAGEMENT_PRACTICE}_${APP_MODULES.BIODIVERSITY_AND_HABITAT}`;
  useCallAmplitudeEvent(eventName);

  return (
    <>
      <Row gutter={16} className="overview-context water-quality">
        {GRAPH_DETAILS.map((graphDetails: GraphDetailsType, index) => {
          return GetGraphComponent(
            { ...graphDetails, waterQuality: true },
            index
          );
        })}
      </Row>
      <Row className="overview-context water-quality">
        <Col span={24}>
          {answerApiLoading ? (
            <InlineLoader
              top="55%"
              position="relative"
              text="Loading graph..."
            />
          ) : (
            <SoilHealth
              graphLabel={"Habitat conservation practices"}
              soilDetails={
                answerApiTransformedData["biodiversity-habitat-conservation"]
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BiodiversityandHabitat;
